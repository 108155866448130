<template>
	<div>
		<!-- 面包屑导航区 -->
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>农机管理</el-breadcrumb-item>
			<el-breadcrumb-item>农机列表</el-breadcrumb-item>
		</el-breadcrumb>
		<!-- 卡片视图 -->
		<el-card>
			<el-row :gutter="20">
				<el-col :span="6">
					<el-input placeholder="请输入内容" v-model="queryInfo.query" clearable @clear="getList">
						<el-button slot="append" icon="el-icon-search" @click="getList"></el-button>
					</el-input>
				</el-col>
				<el-col :span="4">
					<el-button type="primary" @click="goAddPage">添加农机</el-button>
				</el-col>
			</el-row>
			<!-- 表格数据 -->
			<!-- <el-tabs v-model="activeName" style="margin-top: 30px;" @tab-click="tabChange">
				<el-tab-pane label="全部农机" name="all"></el-tab-pane>
				<el-tab-pane label="待审核农机" name="9"></el-tab-pane>
			</el-tabs> -->
			<el-table id="mainTable" :height="tableHeight"  :data="list" border stripe>
				<el-table-column width="125px" label="农机图片">
					<template slot-scope="scope">
						<img :src="scope.row.image" width="100px" height="100px" />
					</template>
				</el-table-column>
				<el-table-column width="150px" label="农机名称" prop="name"></el-table-column>
				<el-table-column width="100px" label="当前状态">
					<template slot-scope="scope">
						<div>{{
							scope.row.statusName
						}}</div>
					</template>
				</el-table-column>
				<el-table-column width="200px" label="服务类型">
					<template slot-scope="scope">
						<el-tag type="success">{{ scope.row.typeName }}</el-tag>
					</template>
				</el-table-column>
				<!-- <el-table-column width="200px" label="服务品种">
					<template slot-scope="scope"><el-tag
							v-for="item in scope.row.varietyName">{{ item }}</el-tag></template>
				</el-table-column> -->
				<el-table-column width="150px" label="农机来源">
					<template slot-scope="scope">
						{{scope.row.fromTypeName}}
					</template>
				</el-table-column>
				<el-table-column width="150px" label="农机手">
					<template slot-scope="scope">
						<el-tag type="info">{{ scope.row.driverName ? scope.row.driverName : "未绑定" }}</el-tag>
					</template>
				</el-table-column>
				<el-table-column width="150px" label="GPS编号" prop="gpsNo">
					<template slot-scope="scope">
						<el-tag type="info">{{ scope.row.gpsNo || "未绑定" }}</el-tag>
					</template>
				</el-table-column>
				<!-- </el-table-column> -->
				<el-table-column width="300px" label="最近一次上报位置">
					<template slot-scope="scope">
						<el-button size="mini" v-if="scope.row.gpsNo" type="primary"
							icon="el-icon-location">{{
							scope.row.lastUploadLocation || "未上报"
						  }}</el-button>
						<el-button size="mini" v-if="!scope.row.gpsNo" type="info"
							icon="el-icon-location">需先绑定gps</el-button>
					</template>
				</el-table-column>
				<!-- <el-table-column label="服务状态">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.state" @change="stateChanged(scope.row)"></el-switch>
					</template>
				</el-table-column> -->
				<el-table-column width="240px" label="创建时间" prop="createTime">
					<template slot-scope="scope">{{scope.row.createtime | dataFormat}}</template>
				</el-table-column>
				<el-table-column label="操作" width="400px" fixed="right">
					<template slot-scope="scope">
						<template v-if="scope.row.auditStatus == 0">
							<el-button type="danger" icon="el-icon-close" size="mini"
								@click="auditAction(scope.row.id, -1)">审核拒绝</el-button>
							<el-button type="primary" icon="el-icon-check" size="mini"
								@click="auditAction(scope.row.id, 1)">审核通过</el-button>
						</template>
						<template v-else>
							<el-button type="primary" icon="el-icon-edit" size="mini"
								@click="editByid(scope.row)">编辑</el-button>
							<el-button v-if="scope.row.status ==1" type="primary" icon="el-icon-add" size="mini"
								@click="bindDriver(scope.row)">{{scope.row.driverId?"换绑农机手":"绑定农机手"}}</el-button>
							<el-button v-if="scope.row.status==1" type="primary" icon="el-icon-add" size="mini"
								@click="bindGPS(scope.row)">{{scope.row.gpsNo?"换绑GPS":"绑定GPS"}}</el-button>
							<el-button v-if="scope.row.status== 1" type="primary" icon="el-icon-add" size="mini"
								@click="bindVideo(scope.row)">{{scope.row.videoUrl?"换绑监控":"绑定监控"}}</el-button>
							<el-button v-if="scope.row.status== 1" type="danger" icon="el-icon-delete" size="mini"
								@click="updateStatus(scope.row.id, -99)">下架</el-button>
							<el-button v-if="scope.row.status == -99" type="success" icon="el-icon-delete" size="mini"
								@click="updateStatus(scope.row.id, 1)">上架</el-button>
							<el-button type="danger" icon="el-icon-delete" size="mini"
								@click="removeByid(scope.row.id)">删除</el-button>
						</template>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页区域 -->
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="queryInfo.page" :page-sizes="[10, 15, 20]" :page-size="queryInfo.limit"
				layout="total, sizes, prev, pager, next, jumper" :total="total" background></el-pagination>
		</el-card>
		<el-dialog :title="isEdit?'修改农机信息':'添加农机信息'" :visible.sync="editDialogVisible" width="50%">
			<AddMachine :detail="currentDetail" :isEdit="isEdit" @refreshList="refreshList" />
		</el-dialog>
		<el-dialog title="绑定农机手" :visible.sync="bindDriverDialogVisible" width="50%">
			<el-form>
				<el-form-item label="选择农机手">
					<el-select width="100%" v-model="bindDriverid" placeholder="请选择农机手">
						<el-option v-for="item in drivers" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<!-- <el-button type="primary" class="btnAdd" @click="bindDriverConfirm">绑定农机手</el-button> -->
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="bindDriverDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="bindDriverConfirm">确 定</el-button>
			</div>
		</el-dialog>
		<el-dialog title="绑定GPS" :visible.sync="bindGPSDialogVisible" width="50%">
			<el-form>
				<el-form-item label="GPS编号">
					<el-input type="number" v-model="bindGpsNo" placeholder="请输入GPS编号"></el-input>
				</el-form-item>
				<!-- <el-button type="primary" class="btnAdd" @click="bindDriverConfirm">绑定农机手</el-button> -->
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="bindGPSDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="bindGPSConfirm">确 定</el-button>
			</div>
		</el-dialog>
		<el-dialog title="绑定监控推流地址" :visible.sync="bindVideoDialogVisible" width="50%">
			<el-form>
				<el-form-item label="监控推流地址编号">
					<el-input v-model="bindVideoUrl" placeholder="请输入监控推流地址"></el-input>
				</el-form-item>
				<!-- <el-button type="primary" class="btnAdd" @click="bindDriverConfirm">绑定农机手</el-button> -->
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="bindVideoDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="bindVideoConfirm">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		machines,
		drivers
	} from "@/config/config.js";
	import {
		machineStatus
	} from "@/config/index";
	import AddMachine from "./Add.vue";
	import { myMixins } from "@/mixins/myMixins.js";
	export default {
		mixins: [myMixins],
		components: {
			AddMachine
		},
		data() {
			return {
				activeName: '1',
				queryInfo: {
					action: "MachineList",
					query: "",
					page: 0,
					limit: 10,
					path: "ashxWeb/Machine.ashx"
				},
				// 农机列表
				list: [],
				// 农机总数
				total: 0,
				editDialogVisible: false,
				isEdit: false,
				currentDetail: null,
				drivers: [],
				bindDriverid: null,
				bindDriverDialogVisible: false,
				bindGPSDialogVisible: false,
				bindGpsNo: null,
				bindVideoDialogVisible: false,
				bindVideoUrl: null,
				serviceTypeList: [],
				serviceVarietyList: [],
				categoryList: []
			};
		},
		created() {
			this.getList();
			this.getDictionaryList();
		},
		methods: {
			async getDictionaryList() {
				this.$getDicList(1).then((list) => {
					this.serviceTypeList = list;
				});
				this.$getDicList(2).then((list) => {
					this.serviceVarietyList = list;
				});
				this.$getDicList(3).then((list) => {
					this.categoryList = list;
				});
				console.log("获取服务")
			},
			refreshList() {
				console.log("更新列表")
				this.getList();
				this.editDialogVisible = false;
			},
			getStatus(status) {
				let color = "";
				switch (Number(status)) {
					case 1:
						color = "green";
						break
					case -99:
					case -9:
						color = "gray";
						break;
					case 2:
					case 9:
						color = "orange";
						break;
				}
				return `<el-tag style="color:${color}">${machineStatus[status]}</el-tag>`;
			},
			// 根据分页获取对应的农机列表
			async getList() {
				// this.list = machines.filter((item)=> item.status > );
				// this.list = machines;
				// this.total = machines.length;
				// return;
				const {
					data: res
				} = await this.$http.get("openapi/web_proxy_get", {
					params: this.queryInfo,
				});
				if (res.code !== 200) {
					return this.$message.error("获取农机列表失败！");
				}
				this.list = res.data && typeof res.data == "object" ? res.data : machines;
				//   console.log(this.list)
				this.total = res.total || this.list.length;
			},
			handleSizeChange(newSize) {
				this.queryInfo.limit = newSize;
				this.getList();
			},
			handleCurrentChange(newSize) {
				this.queryInfo.page = newSize;
				this.getList();
			},
			// 通过id删除农机
			async removeByid(id) {
				const confirmResult = await this.$confirm(
					"此操作将永久删除该农机, 是否继续?",
					"提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					}
				).catch((err) => err);
				if (confirmResult !== "confirm") {
					return this.$message.info("已取消删除！");
				}
				const {
					data: res
				} = await this.$http.post("openapi/proxy_post", {
					action: "MachineDel",
					path: "ashxWeb/Machine.ashx",
					id: id
				});
				if (res.code !== 200) {
					return this.$message.error("删除农机失败！");
				}
				this.$message.success("删除农机成功！");
				this.getList();
			},
			goAddPage() {
				this.isEdit = false;
				this.currentDetail = null;
				this.editDialogVisible = true
				// this.$router.push("/machines/add");
			},
			tabChange(tab, event) {
				console.log(this.activeName);
			},
			bindDriver(row) {
				// this.drivers = drivers;
				this.$http.get("openapi/web_proxy_get", {
					params: {
						page: 0,
						limit: 100,
						action: "DriversList",
						path: "ashxWeb/Drivers.ashx"
					},
				}).then((res) => {
					console.log( res.data.data )
					this.drivers = res.data.data;
					this.currentDetail = row;
					this.bindDriverDialogVisible = true;
				})
			},
			async bindDriverConfirm() {
				const {
					data: res
				} = await this.$http.post("openapi/proxy_post", {
					action: "Bind_driver",
					path: "ashxWeb/Machine.ashx",
					driverId: this.bindDriverid,
					id: this.currentDetail.id
				});
				if (res.code !== 200) {
					return this.$message.error("绑定失败！");
				}
				this.$message.success("绑定成功！");
				this.getList();
				this.bindDriverDialogVisible = false;
			},
			bindGPS(row) {
				this.bindGPSDialogVisible = true;
				this.currentDetail = row;
			},
			async bindGPSConfirm() {
				const {
					data: res
				} = await this.$http.post("openapi/proxy_post", {
					action: "Bind_gps",
					path: "ashxWeb/Machine.ashx",
					gpsId: Number(this.bindGpsNo),
					id: this.currentDetail.id
				});
				if (res.code !== 200) {
					return this.$message.error("绑定失败！");
				}
				this.$message.success("绑定成功！");
				this.bindGPSDialogVisible = false;
			},
			bindVideo(row) {
				this.bindVideoDialogVisible = true;
				this.currentDetail = row;
			},
			async bindVideoConfirm() {
				const {
					data: res
				} = await this.$http.post("openapi/proxy_post", {
					action: "Bind_video",
					path: "ashxWeb/Machine.ashx",
					videoUrl: this.bindVideoUrl,
					id: this.currentDetail.id
				});
				if (res.code !== 200) {
					return this.$message.error("绑定失败！");
				}
				this.$message.success("绑定成功！");
				this.bindVideoDialogVisible = false;
			},
			updateStatus(id, flag) {
				this.$confirm(flag == 1 ? '是否确认上架该农机' : '是否确认下架该农机', '温馨提示', {
					confirmButtonText: '确定',
					callback: async action => {
						if (action == 'confirm') {
							const {
								data: res
							} = await this.$http.post("openapi/proxy_post", {
								action: "UpdateStat",
								path: "ashxWeb/Machine.ashx",
								status: flag,
								id: id
							});
							if (res.code !== 200) {
								return this.$message.error("操作失败！");
							}
							this.$message.success("操作成功！");
							this.getList();
						}
					}
				});
			},
			editByid(current) {
				console.log(current);
				this.isEdit = true;
				this.currentDetail = current;
				this.editDialogVisible = true
			},
			async auditAction(id, flag) {
				const {
					data: res
				} = await this.$http.post("openapi/proxy_post", {
					action: "MachineAudit",
					path: "ashxWeb/Machine.ashx",
					auditStatus: flag,
					id: id
				});
				if (res.code !== 200) {
					return this.$message.error("操作失败！");
				}
				this.$message.success("操作成功！");
				this.getList();
			}
		},
	};
</script>

<style lang="less" scoped></style>