<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>农场管理</el-breadcrumb-item>
      <el-breadcrumb-item>农场列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input
            placeholder="请输入内容"
            v-model="queryInfo.query"
            clearable
            @clear="getFarmList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getFarmList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="goAddPage">添加农场</el-button>
        </el-col>
      </el-row>
      <!-- 表格数据 -->
      <el-table :data="farmList" border stripe>
        <!-- <el-table-column type="index"></el-table-column> -->
        <el-table-column label="农场图片" width="125px">
          <template slot-scope="scope"
            ><img :src="scope.row.image" width="100px" height="100px"
          /></template>
        </el-table-column>
        <el-table-column
          label="农场名称"
          width="150px"
          prop="FarmName"
        ></el-table-column>
        <!-- <el-table-column label="农场特产" width="300px">
          <template slot-scope="scope">
            <el-tag
              type="success"
              v-for="(item, index) in scope.row.products"
              :key="index"
              size="mini"
              >{{ item.name }}</el-tag
            >
          </template>
        </el-table-column> -->
        <el-table-column
          label="联系人"
          prop="contactName"
          width="120px"
        ></el-table-column>
        <el-table-column
          label="联系方式"
          prop="mobile"
          width="120px"
        ></el-table-column>
        <el-table-column
          label="详细地址"
          prop="address"
          width="300px"
        ></el-table-column>
        <el-table-column
          label="详情介绍"
          prop="description"
          width="300px"
        ></el-table-column>
        <el-table-column width="150px" label="状态">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.state"
              @change="stateChanged(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" width="200px">
          <template slot-scope="scope">{{
            scope.row.createtime | dataFormat
          }}</template>
        </el-table-column>
        <el-table-column label="操作" width="200px" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="editInfo(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="removeById(scope.row.farms_id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[10, 15, 20]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </el-card>

    <el-dialog
      :title="isEdit ? '修改农场信息' : '添加农场信息'"
      :visible.sync="editDialogVisible"
      width="50%"
    >
      <AddFarm
        :detail="currentDetail"
        :isEdit="isEdit"
        @refreshList="refreshList"
        @close="editDialogVisible = false"
      />
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import { farmList } from "@/config/config.js";
import AddFarm from "./Add.vue";
export default {
  components: {
    AddFarm,
  },
  data() {
    return {
      queryInfo: {
		    action: "farmList",
        query: "",
        page: 1,
        limit: 10,
		    status: -1,
        path: "ashx/farm.ashx",
      },
      // 农场列表
      farmList: [],
      // 农场总数
      total: 0,
      editDialogVisible: false,
      currentDetail: null,
      isEdit: false,
    };
  },
  created() {
    this.getFarmList();
  },
  methods: {
	refreshList() {
		console.log("更新列表")
		this.getFarmList();
		this.editDialogVisible = false;
	},
    // 根据分页获取对应的农场列表
    async getFarmList() {
      let params = _.cloneDeep(this.queryInfo);
      params.page--;
      const { data: res } = await this.$http.get("openapi/web_proxy_get", {
        params,
      });
      if (res.code !== 200) {
        return this.$message.error("获取农场列表失败！");
      }
      this.farmList = res.data;
      this.total = res.total || this.farmList.length;
    },
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.getFarmList();
    },
    handleCurrentChange(newSize) {
      this.queryInfo.page = newSize;
      this.getFarmList();
    },
    // 通过Id删除农场
    async removeById(id) {
      const confirmResult = await this.$confirm(
        "此操作将永久删除该农场, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除！");
      }
      const { data: res } = await this.$http.delete("farms/" + id);
      if (res.meta.status !== 200) {
        return this.$message.error("删除农场失败！");
      }
      this.$message.success("删除农场成功！");
      this.getFarmList();
    },
    goAddPage() {
      // this.$router.push('/goods/add')
      this.isEdit = false;
      this.currentDetail = null;
      this.editDialogVisible = true;
    },
    updateStatus(id, flag) {},
    editInfo(current) {
      this.isEdit = true;
      // if (current && current.products) {
      //   let list = current.products.map((item) => {
      //     return item.id;
      //   });
      //   current.products = list;
      // }
      // let _current = _.cloneDeep(current);
      let _current =current;
      _current.farmName = _current.FarmName;
      _current.name = _current.contactName;
      _current.Lng = _current.lng;
      console.log(_current)
      setTimeout(() => {
        this.currentDetail = current;
      this.editDialogVisible = true;
      }, 100);
      
    },
    closeDialog() {
      this.isEdit = false;
      this.currentDetail = null;
      this.editDialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped></style>
