<template>
  <div>
    <!-- 面包屑导航区 -->
    <!-- 		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>农场管理</el-breadcrumb-item>
			<el-breadcrumb-item>添加农场</el-breadcrumb-item>
		</el-breadcrumb> -->
    <!-- 卡片视图 -->
    <el-card>
      <!-- 提示 -->
      <el-alert
        title="添加农场信息"
        type="info"
        center
        show-icon
        :closable="false"
      ></el-alert>
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="100px"
        label-position="top"
      >
        <el-form-item label="农场名称">
          <el-input v-model="addForm.farmName"></el-input>
        </el-form-item>
        <!-- <el-form-item label="农场面积(亩)" prop="machines_area">
							<el-input v-model="addForm.area" type="number"></el-input>
						</el-form-item> -->
        <el-form-item label="农场图片">
          <upload
            name="image"
            :initFileList="initFileList"
            @handleUploadSuccess="handleUploadSuccess"
            @handleRemove="handleRemove"
          ></upload>
        </el-form-item>
      
        <!-- <el-form-item label="农产品列表">
          <el-select
            v-model="addForm.includeIds"
            multiple
            filterable
            default-first-option
            placeholder="请选择农产品"
          >
            <el-option
              v-for="(item, index) in productList"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="通讯地址" width="200px">
          <el-button type="primary" @click="selectMapAddress"
            >选择通讯坐标地址</el-button
          >
          <div style="display: flex">
            <el-input
              width="200px"
              placeholder="详细地址"
              v-model="addForm.address"
            ></el-input>
            <el-input
              width="200px"
              placeholder="经度，选择后自动获取"
              disabled
              v-model="addForm.lat"
            ></el-input>
            <el-input
              width="200px"
              placeholder="纬度，选择后自动获取"
              disabled
              v-model="addForm.lng"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="负责人姓名">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="addForm.mobile"></el-input>
        </el-form-item>
        <!-- <el-form-item label="备注">
          <el-input v-model="addForm.remark"></el-input>
        </el-form-item> -->
        <el-form-item label="农场详情介绍">
          <el-input
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 20 }"
            placeholder="农场详情介绍"
            v-model="addForm.description">
          </el-input>
        </el-form-item>
      </el-form>
      <div style="width: 100%">
        <el-button
          style="width: 200px"
          type="primary"
          class="btnAdd"
          @click="addAction"
          size="large"
          >添加农场</el-button
        >
        <el-button @click="cancelAdd" style="width: 200px; margin-left: 20px"
          >取消</el-button
        >
      </div>
    </el-card>

    <el-dialog
      :title="mapDialogTitle"
      :visible.sync="addressDialogVisible"
      width="50%"
      appendToBody
      @close="addressDialogClosed"
    >
      <AmapChose
        ref="mapChoose"
        :initLocation="initLocation"
        :onlyShowMap="onlyShowMap"
        :show="addressDialogVisible"
        @placeChoose="placeChoose"
        @changeShow="changeShow"
      ></AmapChose>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelChooseAddress">取 消</el-button>
        <el-button type="primary" @click="confirmChooseAddress"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import upload from "@/components/upload";
import { farm_goodsList } from "@/config/config.js";
import AmapChose from "@/components/amap-choose/amap-choose.vue";
export default {
  components: {
    upload,
    AmapChose,
  },
  props: {
    detail: Object,
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 步骤条默认激活 与左侧Tab联动
      addForm: {
        farmName: "",
        // area: "",
        description: "",
        image: "",
        name: "",
        mobile: "",
        address: "",
        lat: "",
        lng: "",
        // remark: "",
        // includeIds: "",
      },
      bindVideoDialogVisible: false,
      bindVideoUrl: null,
      addFormRules: {
      },
      // 农场列表
      productList: [],
      // 级联选择器配置
      cascaderProps: {
        expandTrigger: "hover",
        label: "cat_name",
        value: "cat_id",
        children: "children",
      },
      currentProduct: null,
      initFileList: [],
      initLocation: "",
      addressDialogVisible: false,
      tempAddressInfo: {},
      mapDialogTitle: "选择地址",
      onlyShowMap: false,
    };
  },
  watch: {
    detail: {
      handler(nval, oval) {
        if (nval) {
						let _newObj = _.cloneDeep(nval);
						for (let key in this.addForm) {
							this.addForm[key] = _newObj[key];
						}
						if (this.addForm.image) {
							this.initFileList = [{
								url: this.addForm.image
							}];
						} else {
							this.initFileList = [];
						}
					} else {

					}
      },
      deep: true,
      immediate: true,
    },
    // "addForm.products": {
    // 	handler(nval, oval) {
    // 		if (!this.addForm.products || !this.addForm.products.length) {
    // 			this.productSelectList = [];
    // 		} else {
    // 			console.log('123', nval)
    // 			let selected = [];
    // 			if(){

    // 			}
    // 			selected = nval.map((item) => {
    // 				return this.productList.find((_item) => _item.id == item);
    // 			});
    // 			this.productSelectList = selected;
    // 		}

    // 		// return selected;
    // 	},
    // 	deep: true,
    // 	immediate: true
    // }
  },
  created() {
    this.getProductList();
  },
  computed: {
    productSelectList() {
      if (!this.addForm.products || !this.addForm.products.length) {
        return [];
      }
      let selected = [];
      selected = this.addForm.products.map((item) => {
        return this.productList.find((_item) => _item.id == item);
      });
      return selected;
    },
  },
  methods: {
    addVideo() {
      this.addForm.videos.push({
        src: "",
        key: Date.now(),
      });
    },
    bindVideo(row) {
      this.currentProduct = row;
      if (row.videoUrl) {
        this.bindVideoUrl = row.videoUrl;
      }
      this.bindVideoDialogVisible = true;
    },
    cancleBindVideo() {
      this.currentProduct = null;
      this.bindVideoDialogVisible = false;
    },
    bindVideoConfirm() {
      let index = this.addForm.products.find(
        (item) => item.id == this.currentProduct.id
      );
      if (index >= 0) {
        this.addForm.products[index].videoUrl = this.bindVideoUrl;
      }
      this.bindVideoDialogVisible = false;
    },
    removeVideo(item) {
      var index = this.addForm.videos.indexOf(item);
      if (index !== -1) {
        this.addForm.videos.splice(index, 1);
      }
    },
    // 获取农场分类数据列表
    async getProductList() {
      this.productList = farm_goodsList;
      return;
      const { data: res } = await this.$http.get("categories");
      if (res.meta.status !== 200) {
        return this.$message.error("获取农场列表失败！");
      }
      this.cateList = res.data;
    },
    beforeTabLeave(activeName, odlActiveName) {},
    // Tab标签被选中时触发
    async tabClicked() {},
    handleRemove() {
      this.addForm.image = "";
    },
    // 监听图片上传成功事件
    handleUploadSuccess(response) {
      this.addForm.image = response.value[0].url;
    },
    addAction() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) return this.$message.error("请填写必要的表单项！");
        // 发送请求前：需对提交的表单进行处理goods_cat attrs
        // this.addForm.goods_cat = this.addForm.goods_cat.join(',')
        // 以上写法不对：级联选择器绑定的对象goods_cat要求是数组对象
        // 解决办法: 包：lodash 方法（深拷贝）：cloneDeep(boj)

        let params = _.cloneDeep(this.addForm);
        if (this.isEdit) {
          params.action = "farmEdit";
          params.id = this.detail.id;
        } else {
          params.action = "farmAdd";
        }
        params.path = "/ashxWeb/web_farm.ashx";
        const { data: res } = await this.$http.post(
          "/openapi/proxy_post",
          params
        );
        if (res.code !== 200) return this.$message.error("添加农场失败！");
        this.$message.success("添加农场成功!");
        // this.$router.push("/service");
        this.$emit("refreshList");
      });
    },
    cancelAdd() {
      this.$emit("close");
    },
    changeShow() {},
    placeChoose(result) {
      console.log(result);
      if (result) {
        this.addForm.address = result.address;
        this.addForm.lat = result.latitude;
        this.addForm.lng = result.longitude;
      }
    },
    cancelChooseAddress() {
      this.addressDialogVisible = false;
    },
    confirmChooseAddress() {
      this.$refs["mapChoose"].confirm();
      this.addressDialogVisible = false;
    },
    addressDialogClosed() {
      this.addressDialogVisible = false;
    },
    selectMapAddress() {
      this.mapDialogTitle = "选择坐标位置";
      this.onlyShowMap = false;
      this.addressDialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.el-checkbox {
  margin: 0 8px 0 0 !important;
}

.previewImg {
  width: 100%;
}

.btnAdd {
  margin-top: 15px;
}
</style>
