<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>土地租赁管理</el-breadcrumb-item>
      <el-breadcrumb-item>土地列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
      <!-- 搜索 添加 -->
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input
            placeholder="请输入内容"
            v-model="queryInfo.query"
            clearable
            @clear="getList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getList"
            ></el-button>
          </el-input>
        </el-col>
        <!-- <el-col :span="4">
            <el-button type="primary" @click="addDialogVisible = true">添加会员</el-button>
          </el-col> -->
      </el-row>
      <!-- 用户列表区域 -->
      <el-table :data="recordList" border stripe>
        <!-- stripe: 斑马条纹
          border：边框-->
        <el-table-column
          width="150px"
          prop="id"
          label="合同编号"
        ></el-table-column>
        <el-table-column
          width="150px"
          prop="land_name"
          label="租赁板块名称"
        ></el-table-column>
        <el-table-column
          width="150px"
          prop="land_id"
          label="租赁板块编号"
        ></el-table-column>
        <el-table-column width="150px" label="租赁面积">
            <template slot-scope="scope"><el-tag type="default" class="bold" size="mini">{{
            scope.row.area
          }}</el-tag></template>
        </el-table-column>
        <el-table-column
          width="150px"
          prop="leaser_name"
          label="租赁人姓名"
        ></el-table-column>
        <el-table-column
          width="150px"
          prop="leaser_mobile"
          label="租赁人电话"
        ></el-table-column>
        <el-table-column width="300px" label="租赁时间">
          <template slot-scope="scope">{{
            scope.row.lease_start + " 至 " + scope.row.lease_end
          }}</template>
        </el-table-column>
        <el-table-column width="250px" label="租赁合同文件">
          <template slot-scope="scope">
            <el-link
              v-if="scope.row.contract"
              :href="scope.row.contract"
              target="_blank"
              >点击查看合同<i class="el-icon-view el-icon--right"></i>
            </el-link>
          </template>
        </el-table-column>
        <el-table-column label="合同创建时间" width="180px">
            <template slot-scope="scope">{{scope.row.create_time | dataFormat }}</template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope"> </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[2, 5, 10, 15]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>
  </div>
</template>

<script>
import { landLeaseList } from "@/config/config.js";
import _ from "lodash";
export default {
  data() {
    return {
      queryInfo: {
        // action: "recordList",
        query: "",
        page: 1,
        limit: 10,
        // path: "ashxWeb/record.ashx",
      },
      // 资讯列表
      recordList: [],
      // 资讯总数
      total: 0,
      currentDetail: null,
      isEdit: false,
      editDialogVisible: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    refreshList() {
      console.log("更新列表");
      this.getList();
      this.editDialogVisible = false;
    },
    // 根据分页获取对应的资讯列表
    async getList() {
      let params = _.cloneDeep(this.queryInfo);
      params.page--;
      const { data: res } = await this.$http.get("web_api/landLease/leaseRecords", {
        params,
      });
      if (res.code !== 200) {
        return this.$message.error("获取土地租赁合同列表失败！");
      }
      this.recordList = res.data;
      this.total = res.total || this.recordList.length;
    },
    handleSizeChange(size) {
      this.queryInfo.limit = size;
      this.getList();
    },
    handleCurrentChange(size) {
      this.queryInfo.page = size;
      this.getList();
    },
    // 通过Id删除资讯
    async removeById(id) {
      this.$confirm("是否删除该资讯", "温馨提示", {
        confirmButtonText: "确定",
        callback: async (action) => {
          if (action == "confirm") {
            // const { data: res } = await this.$http.post("openapi/proxy_post", {
            //   action: "record_delete",
            //   path: "ashxWeb/record.ashx",
            //   id,
            // });
            // if (res.code !== 200) {
            //   return this.$message.error("操作失败！");
            // }
            // this.$message.success("操作成功！");
            // this.getList();
          }
        },
      });
    },
    goAddPage() {
      // this.$router.push('/goods/add')
      this.isEdit = false;
      this.currentDetail = null;
      this.editDialogVisible = true;
    },
    updateStatus(id, flag) {},
    editInfo(current) {
      this.isEdit = true;
      setTimeout(() => {
        this.currentDetail = current;
        this.editDialogVisible = true;
      }, 100);
    },
    closeDialog() {
      this.isEdit = false;
      this.currentDetail = null;
      this.editDialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped></style>
