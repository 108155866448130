<template>
	<div class="upload-container">
		<el-upload :multiple="multiple" :file-list="pics" :action="uploadURL" :on-preview="handlePreview"
			:on-remove="handleRemove" :headers="headerObj" :list-type="preview?'picture':'text'" :on-success="handleSuccess">
			<el-button size="small" type="primary">点击上传</el-button>
		</el-upload>
		<el-dialog title="图片预览" :visible.sync="previewDialogVisible" width="50%">
			<img :src="picPreviewPath" alt="" class="previewImg" />
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: "none",
		props: {
			initFileList: {
				type: Array,
				default () {
					return []
				},
			},
			name: {
				type: String,
				default: "",
			},
			multiple: {
				type: Boolean,
				default: false
			},
			preview:{
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				// 图片上传地址
				uploadURL: this.$baseURL +  "/openapi/upload",
				// uploadURL: "http://127.0.0.1:8888/api/private/v1/upload",
				// 图片上传组件的请求对象
				headerObj: {
					mytoken: window.sessionStorage.getItem("token"),
				},
				picPreviewPath: "",
				// 图片预览对话框
				previewDialogVisible: false,
				pics: [],
			};
		},
		watch: {
			initFileList: {
				immediate: true,
				deep: true,
				handler(nval, oval) {
					this.pics = nval;
				},
			}
		},
		mounted() {},
		methods: {
			// 处理图片预览
			handlePreview(file) {
				console.log(file)
				this.picPreviewPath = file.url;
				this.previewDialogVisible = true;
			},
			// 处理移除图片的操作
			handleRemove(file) {
				console.log(file);
				// 1.获取将要删除图片的临时路径
				const filePath = file.url;
				// 2.从pics数组中，找到图片对应的索引值
				const i = this.pics.findIndex((x) => x.url === filePath);
				// 3.调用splice方法，移除图片信息
				this.pics.splice(i, 1);
				this.$emit("handleRemove", {
					name: this.name,
					value: this.pics,
				});
			},
			// 监听图片上传成功事件
			handleSuccess(response) {
				console.log(response)
				if (response.data && response.data.url) {
					// 1.拼接得到一个图片信息对象 临时路径
					console.log(response.data.url)
					const picInfo = {
						url: response.data.url,
					};
					// 2.将图片信息对象，push到pics数组中
					this.pics.push(picInfo);
					this.$emit("handleUploadSuccess", {
						name: this.name,
						value: this.pics,
					});
				}
			},
		},
	};
</script>

<style lang="less">
	.upload-container {}

	.el-checkbox {
		margin: 0 8px 0 0 !important;
	}

	.previewImg {
		width: 100%;
	}

	.btnAdd {
		margin-top: 15px
	}
</style>