<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>农机手管理</el-breadcrumb-item>
      <el-breadcrumb-item>农机手列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
      <!-- 搜索 添加 -->
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input
            placeholder="请输入内容"
            v-model="queryInfo.query"
            clearable
            @clear="getList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="addDialogVisible = true"
            >添加农机手</el-button
          >
        </el-col>
      </el-row>
      <!-- 用户列表区域 -->
      <el-table id="mainTable" :height="tableHeight" :data="list" border stripe>
        <!-- stripe: 斑马条纹
          border：边框-->
        <el-table-column
          width="100px"
          prop="name"
          label="姓名"
        ></el-table-column>
        <el-table-column
          width="180px"
          prop="id_card"
          label="身份证"
        ></el-table-column>
        <el-table-column
          width="150px"
          prop="mobile"
          label="手机号"
        ></el-table-column>
        <el-table-column width="125px" label="身份证人像面">
          <template slot-scope="scope">
            <img
              v-if="scope.row.id_card_front"
              :src="scope.row.id_card_front"
              width="100px"
              height="100px"
            />
          </template>
        </el-table-column>
        <el-table-column width="125px" label="身份证国徽面">
          <template slot-scope="scope">
            <img
              v-if="scope.row.id_card_backend"
              :src="scope.row.id_card_backend"
              width="100px"
              height="100px"
            />
          </template>
        </el-table-column>
        <el-table-column width="200px" label="擅长机型">
          <template slot-scope="scope">
            <el-tag
              v-for="(item, index) in getCategoryString(scope.row.category)"
              :key="index"
              >{{ item }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column width="100px" label="来源">
          <template slot-scope="scope">
            {{ scope.row.fromType == "1" ? "第三方" : "平台" }}
          </template>
        </el-table-column>
        <el-table-column width="100px" label="是否已审核">
          <template slot-scope="scope">
            {{ getAuditStatus(scope.row.auditStatus) }}
          </template>
        </el-table-column>
        <!-- <el-table-column width="100px" label="状态">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status == 1 ? true : false"
              @change="stateChanged(scope.row)"
            ></el-switch>
          </template>
        </el-table-column> -->
        <el-table-column
          width="200px"
          label="备注"
          prop="remark"
        ></el-table-column>
        <el-table-column fixed="right" width="400px" label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="editInfo(scope.row)"
              >编辑</el-button
            >
            <el-button
              v-if="scope.row.auditStatus == 0"
              type="fail"
              icon="el-icon-close"
              size="mini"
              @click="auditAction(scope.row.id, -1)"
              >驳回</el-button
            >
            <el-button
              v-if="scope.row.auditStatus == 0"
              type="success"
              icon="el-icon-check"
              size="mini"
              @click="auditAction(scope.row.id, 1)"
              >通过</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="removeUserByid(scope.row.id)"
              >删除</el-button
            >
            <el-button
              type="primary"
              icon="el-icon-lock"
              size="mini"
              @click="resetPassword(scope.row)"
              >重置密码</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[2, 5, 10, 15]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>

    <!-- 添加用户的对话框 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClosed"
    >
      <!-- 内容主体 -->
      <el-form
        :model="addForm"
        ref="addFormRef"
        :rules="addFormRules"
        label-width="100px"
      >
        <el-form-item label="姓名" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="id_card">
          <el-input v-model="addForm.id_card"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="mobile">
          <el-input v-model="addForm.mobile"></el-input>
        </el-form-item>
        <el-form-item label="来源" prop="from">
          <el-select
            width="100%"
            v-model="addForm.from"
            placeholder="请选择来源"
          >
            <el-option
              v-for="item in fromList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="擅长机型" prop="category">
          <el-select
            width="100%"
            v-model="addForm.category"
            multiple
            placeholder="请选择擅长机型"
          >
            <el-option
              v-for="item in categoryList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="登录密码" prop="password">
          <el-input v-model="addForm.password" type="password"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="addForm.remark" type="text"></el-input>
        </el-form-item>
        <el-form-item label="身份证正面">
          <upload
            name="id_card_front"
            :initFileList="
              addForm.id_card_front ? [{ url: addForm.id_card_front }] : []
            "
            @handleUploadSuccess="handleUploadSuccess"
            @handleRemove="handleRemove"
          ></upload>
        </el-form-item>
        <el-form-item label="身份证反面">
          <upload
            name="id_card_backend"
            :initFileList="
              addForm.id_card_backend ? [{ url: addForm.id_card_backend }] : []
            "
            @handleUploadSuccess="handleUploadSuccess"
            @handleRemove="handleRemove"
          ></upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="重置登录密码"
      :visible.sync="resetPasswordDialogVisible"
      width="50%"
      @close="resetPasswordDialogClose"
    >
      <el-form>
        <el-form-item label="登录密码">
          <el-input
            v-model="password"
            type="password"
            placeholder="请输入登录密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认登录密码">
          <el-input
            v-model="confirmPassword"
            type="password"
            placeholder="请再次输入登录密码"
          ></el-input>
        </el-form-item>
        <!-- <el-button type="primary" class="btnAdd" @click="bindDriverConfirm">绑定农机手</el-button> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetPasswordDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="resetPasswordConfirm"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import upload from "@/components/upload";
import { drivers } from "@/config/config.js";
import _ from 'lodash';
import { myMixins } from "@/mixins/myMixins.js";
export default {
  mixins: [myMixins],
  data() {
    // 自定义手机号规则
    var checkMobile = (rule, value, callback) => {
      const regMobile = /^1[34578]\d{9}$/;
      if (regMobile.test(value)) {
        return callback();
      }
      // 返回一个错误提示
      callback(new Error("请输入合法的手机号码"));
    };
    return {
      // 获取用户列表查询参数对象
      queryInfo: {
        action: "DriversList",
        query: "",
        page: 0,
        limit: 10,
        path: "ashxWeb/Drivers.ashx",
      },
      fromList: [
        {
          label: "平台",
          value: 0,
        },
        {
          label: "第三方",
          value: 1,
        },
      ],
      list: [],
      total: 0,
      isEditUser: false,
      // 添加用户对话框
      addDialogVisible: false,
      currentUser: null,
      // 用户添加
      addForm: {
        name: "",
        id_card: "",
        mobile: "",
        from: "",
        id_card_front: "",
        id_card_backend: "",
        category: "",
        remark: "",
        password: "",
      },
      // 用户添加表单验证规则
      addFormRules: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        id_card: [
          {
            required: true,
            message: "请输入身份证号",
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入登录密码",
            trigger: "blur",
          },
        ],
        categoty: [
          {
            required: true,
            message: "请选择擅长机型",
            trigger: "blur",
          },
        ],
      },
      categoryList: [],
      password: null,
      confirmPassword: null,
      resetPasswordDialogVisible: false,
    };
  },
  components: {
    upload,
  },
  created() {
    this.getDictionaryList();
  },
  computed: {
    dialogTitle() {
      return this.isEditUser ? "编辑农机手信息" : "添加农机手";
    },
  },
  methods: {
    async getDictionaryList() {
      this.$getDicList(3).then((list) => {
        this.categoryList = list;
        this.getList();
      });
      console.log("获取服务");
    },
    getCategoryString(cate) {
      let cates = cate.split(",");
      let res = [];
      cates.forEach((value) => {
        let info = this.categoryList.find((item) => item.value == value);
        res.push(info.name);
      });
      return res;
    },
    getAuditStatus(status) {
      let str;
      switch (Number(status)) {
        case 0:
          str = "待审核";
          break;
        case 1:
          str = "已审核";
          break;
        case -1:
          str = "已驳回";
          break;
      }
      return str;
    },
    handleRemove(response) {
      if (response && response.name == "id_card_front") {
        this.addForm.id_card_front = "";
      }
      if (response && response.name == "id_card_backend") {
        this.addForm.id_card_backend = "";
      }
    },
    // 监听图片上传成功事件
    handleUploadSuccess(response) {
      if (response && response.name == "id_card_front") {
        this.addForm.id_card_front = response.value[0].url;
      }
      if (response && response.name == "id_card_backend") {
        this.addForm.id_card_backend = response.value[0].url;
      }
    },
    async getList() {
      const { data: res } = await this.$http.get("openapi/web_proxy_get", {
        params: this.queryInfo,
      });
      if (res.code !== 200) {
        return this.$message.error("获取农机手列表失败！");
      }
      this.list = res.data && typeof res.data == "object" ? res.data : drivers;
      this.total = res.total || this.list.length;
    },
    // 监听 limit改变的事件
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.queryInfo.limit = newSize;
      this.getList();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      // console.log(newSize)
      this.queryInfo.page = newSize;
      this.getList();
    },
    // 监听 switch开关 状态改变
    async stateChanged(userInfo) {
      const { data: res } = await this.$http.post("openapi/proxy_post", {
        action: "UpdateStatus",
        path: "ashxWeb/Drivers.ashx",
        driverId: userInfo.id,
        status: userInfo.status,
      });
      if (res.code !== 200) {
        userInfo.status = !userInfo.status;
        return this.$message.error("更新状态失败");
      }
      this.$message.success("更新用户成功！");
    },
    // 监听 添加用户对话框的关闭事件
    addDialogClosed() {
      this.$refs.addFormRef.resetFields();
    },
    // 编辑用户信息
    editInfo(row) {
      this.isEditUser = true;
      this.currentUser = row;
      let _newObj = _.cloneDeep(row);
      let category = _newObj.category.split(",");
      _newObj.category = category;
      _newObj.from = Number(_newObj.fromType);
      for (let key in this.addForm) {
        this.addForm[key] = _newObj[key];
      }

      // if (this.addForm.id_card_front) {
      // 	this.initFileList = [{
      // 		url: this.addForm.image
      // 	}];
      // } else {
      // 	this.initFileList = [];
      // }
      this.addDialogVisible = true;
    },
    // 监听修改用户对话框的关闭事件
    addDialogClosed() {
      this.addDialogVisible = false;
      this.isEditUser = false;
      this.currentUser = null;
    },
    // 添加用户
    addUser() {
      // 提交请求前，表单预验证
      this.$refs.addFormRef.validate(async (valid) => {
        // console.log(valid)
        // 表单预校验失败
        if (!valid) return;
        let params = _.cloneDeep(this.addForm);
        // params.type = Number(params.type);
        // params.variety = Number(params.variety);
        params.category = params.category.join(",");
        // params.from = Number(params.fromType);
        // let params = this.$utils.dataFormate(_params)
        if (this.isEditUser) {
          params.action = "DriverEdit";
          params.driverId = this.currentUser.id;
        } else {
          params.action = "DriverAdd";
        }
        params.path = "ashxWeb/Drivers.ashx";
        // 发起请求添加农机
        const { data: res } = await this.$http.post(
          "openapi/proxy_post",
          params
        );
        if (res.code !== 200) return this.$message.error("添加农机手失败！");
        this.$message.success("添加农机手成功!");
        this.addDialogVisible = false;
        this.getList();
      });
    },
    // 删除用户
    async removeUserByid(id) {
      const confirmResult = await this.$confirm(
        "此操作将永久删除该用户, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.post("openapi/proxy_post", {
        action: "DriverDel",
        path: "ashxWeb/Drivers.ashx",
        driverid: id,
      });
      if (res.code !== 200) return this.$message.error("删除失败！");
      this.$message.success("删除成功！");
      this.getList();
    },
    async auditAction(id, flag) {
      const { data: res } = await this.$http.post("openapi/proxy_post", {
        action: "DriverUpdate",
        path: "ashxWeb/Drivers.ashx",
        auditStatus: flag,
        driverId: id,
      });
      if (res.code !== 200) {
        return this.$message.error("操作失败！");
      }
      this.$message.success("操作成功！");
      this.getList();
    },
    resetPasswordDialogClose() {
      this.currentUser = null;
      this.resetPasswordDialogVisible = false;
    },
    resetPassword(row) {
      this.currentUser = row;
      this.resetPasswordDialogVisible = true;
    },
    async resetPasswordConfirm(id) {
      if (!this.password || this.confirmPassword != this.password) {
        return this.$message.error("密码不一致！");
      }
      const { data: res } = await this.$http.post("openapi/proxy_post", {
        action: "UpdatePassword",
        path: "ashxWeb/Drivers.ashx",
        password: this.password,
        driverId: this.currentUser.id,
      });
      if (res.code !== 200) {
        return this.$message.error("更新失败！");
      }
      this.$message.success("更新成功！");
      this.resetPasswordDialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped></style>
