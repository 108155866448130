<template>
	<div>
		<!-- 面包屑导航区 -->
		<!-- <el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>农机管理</el-breadcrumb-item>
			<el-breadcrumb-item>添加农机</el-breadcrumb-item>
		</el-breadcrumb> -->
		<!-- 卡片视图 -->
		<el-card>
			<!-- 提示 -->
			<el-alert title="农机信息" type="info" center show-icon :closable="false"></el-alert>
			<el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px" label-position="top">
				<el-form-item label="农机名称" prop="name">
					<el-input v-model="addForm.name"></el-input>
				</el-form-item>
			<!-- 	<el-form-item label="农机类型" prop="category">
					<el-select style="width: 100%" v-model="addForm.type" placeholder="请选择农机类型">
						<el-option v-for="item in categoryList" :key="item.value" :label="item.name"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item> -->
			<el-form-item label="服务类型" prop="type">
					<el-select style="width: 100%" v-model="addForm.type" placeholder="请选择服务类型">
						<el-option v-for="item in categoryList" :key="item.value" :label="item.name"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="服务品种" prop="variety">
					<el-select width="100%" v-model="addForm.variety" placeholder="请选择服务品种">
						<el-option v-for="item in serviceVarietyList" :key="item.value" :label="item.name"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item> 
				<el-form-item label="农机品牌">
					<el-input v-model="addForm.brand"></el-input>
				</el-form-item>
				<el-form-item label="农机马力">
					<el-input v-model="addForm.power"></el-input>
				</el-form-item>
				<el-form-item label="农机图片" prop="image">
					<upload name="image" :initFileList="initFileList" @handleUploadSuccess="handleUploadSuccess"
						@handleRemove="handleRemove"></upload>
				</el-form-item>
				<el-form-item label="农机来源" prop="from">
					<el-select width="100%" v-model="addForm.from" placeholder="请选择农机来源">
						<el-option v-for="item in machineFromList" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="农机内容" prop="descrption">
					<el-input type="textarea" :autosize="{ minRows: 5, maxRows: 20}" placeholder="农机内容"
						v-model="addForm.description">
					</el-input>
				</el-form-item>
				<!-- <el-form-item label="农机内容">
          <upload
            style="opacity:0"
            name="description_image"
            @handleUploadSuccess="handleUploadSuccess"
          ></upload>
          <quill-editor
            ref="editor"
            v-model="addForm.introduce"
          ></quill-editor> -->
				<!-- 添加农机 -->
				<el-button type="primary" class="btnAdd" @click="addMachine">添加农机</el-button>
				<!-- </el-form-item> -->
			</el-form>
		</el-card>
	</div>
</template>

<script>
	import _ from "lodash";
	import {
		serviceTypeList,
		serviceVarietyList
	} from "@/config/config.js";
	import upload from "@/components/upload";
	export default {
		components: {
			upload,
		},
		props: {
			detail: Object,
			isEdit: {
				type: Boolean,
				default: false
			}
		},
		watch: {
			detail: {
				handler(nval, oval) {
					if (nval) {
						nval.type = nval.type ? "" + nval.type : null;
						// nval.variety = nval.variety ? "" + nval.variety : null;
						// nval.category = nval.category ? nval.category.split(",") : null;
						nval.from = (nval.fromType == "平台" || nval.fromType == 0) ? 0 : 1;
						let _newObj = _.cloneDeep(nval);
						for (let key in this.addForm) {
							this.addForm[key] = _newObj[key];
						}
						if (this.addForm.image) {
							this.initFileList = [{
								url: this.addForm.image
							}];
						} else {
							this.initFileList = [];
						}
					} else {

					}
				},
				deep: true,
				immediate: true
			}
		},
		data() {
			return {
				initFileList: [],
				addForm: {
					name: "",
					type: "",
					brand: "",
					power: "",
					category: "",
					variety: [],
					image: "",
					description: "",
					from: ""
				},
				machineFromList: [{
						label: "平台",
						value: 0,
					},
					{
						label: "第三方",
						value: 1,
					},
				],
				brandList: [{
					label: "其它",
					value: "other",
				}, ],
				addFormRules: {
					name: [{
						required: true,
						message: "请输入农机名称",
						trigger: "blur",
					}, ],
					category: [{
						required: true,
						message: "请选择农机类型",
						trigger: "blur",
					}, ],
					type: [{
						required: true,
						message: "请选择服务类型",
						trigger: "blur",
					}, ],
					variety: [{
						required: true,
						message: "请选择服务品种",
						trigger: "blur",
					}, ],
					brand: [{
						required: true,
						message: "请输入农机品牌",
						trigger: "blur",
					}, ],
					image: [{
						required: true,
						message: "请上传农机照片",
						trigger: "blur",
					}, ],
					description: [{
						required: true,
						message: "请填写农机介绍",
						trigger: "blur",
					}, ],
					from: [{
						required: true,
						message: "请填写农机介绍",
						trigger: "blur",
					}, ],
				},
				// serviceTypeList:[],
				// serviceVarietyList:[],
				// categoryList:[]
			};
		},
		created() {

		},
		computed: {
			serviceTypeList() {
				return this.$parent.$parent.serviceTypeList;
			},
			serviceVarietyList() {
				return this.$parent.$parent.serviceVarietyList;
			},
			categoryList() {
				return this.$parent.$parent.categoryList;
			}
		},
		methods: {
			handleRemove() {
				this.addForm.image = ""
			},
			// 监听图片上传成功事件
			handleUploadSuccess(response) {
				if (response && response.name == "image") {
					this.addForm.image = response.value[0].url;
				}
			},
			// 添加农机
			addMachine() {
				this.$refs.addFormRef.validate(async (valid) => {
					if (!valid) return this.$message.error("请填写必要的表单项！");
					// 发送请求前：需对提交的表单进行处理cat attrs
					let params = _.cloneDeep(this.addForm)
					params.type = Number(params.type);
					params.variety = Number(params.variety);
					params.category = Number(params.category);
					// params.category = params.category.join(",");
					if (this.isEdit) {
						params.action = "MachineEdit"
						params.id = this.detail.id;
					} else {
						params.action = "MachineAdd";
					}
					params.path = "ashxWeb/Machine.ashx"
					// 发起请求添加农机
					const {
						data: res
					} = await this.$http.post("openapi/proxy_post", params);
					if (res.code !== 200)
						return this.$message.error("添加农机失败！");
					this.$message.success("添加农机成功!");
					this.$emit("refreshList");
					// this.$router.push("/machines");
				});
			},
		},
	};
</script>

<style lang="less" scoped>
	.el-checkbox {
		margin: 0 8px 0 0 !important;
	}

	.previewImg {
		width: 100%;
	}

	.btnAdd {
		margin-top: 15px;
	}
</style>