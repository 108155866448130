<template>
  <div class="amap-choose">
   
    <div class="info-content">
      <div class="map-box">
        <div id="map-container"></div>
      </div>
      <div class="content" v-if="!onlyShowMap">
        <div class="search">
          <div>
            <image src="./search.png"></image>
            <el-input
              placeholder="搜索地点"
              @input="inputValue"
              v-model="keyword"
            />
          </div>
          <el-button @click="cancel">取消</el-button>
        </div>
        <div class="main" >
          <div
            class="choose-item"
            v-for="(item, index) in list"
            :key="index"
            @click="toChecked(item)">
            <div>
              <div class="name">{{ item.name }}</div>
              <div class="detail">
                {{ item.district }}{{ item.address ? "-" + item.address : "" }}
              </div>
            </div>
            <div>
              <div class="select-flag" :style="{ color }" v-if="item.location === checked.location">
                √
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="bottom-button">
      <el-button class="cancel" @tap="toCancel">取消</el-button>
      <el-button class="conform" :style="{ backgroundColor: color }"  @click="confirm">完成</el-button>
    </div> -->
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
let AMap;
export default {
  props: {
    initLocation: {
      type: String,
      default: "",
    },
    onlyShowMap:{
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      keyMap: "88ff4caf7acbe262dc2fe98e757cb8be",
      AMapKeyWeb: "7094d6e6f20ede19da379b01d7cf4dda",
      color: "#232323",
      list: [],
      keyword: "",
      defaultLocation:"116.787141,32.573306",
      timeId: null,
      checked: {
        locaiton: "",
      },
      AMap: null,
      map: null,
      showPlace: false,
    };
  },
  mounted() {
    console.log(AMapLoader);
    AMapLoader.load({
      key: this.keyMap,
      version: "2.0", // 或者根据需要指定版本号
      plugins: ["AMap.Geolocation"], // 如果需要使用插件，可以在这里指定
    }).then((_AMap) => {
      AMap = _AMap;
      this.initMapWeb();
    });
    // this.showPlace = this.show;
    // var script = document.getElementById('mapTest')
    // let that = this;
    // script ? this.loop() : (function (){
    // 	var script = document.createElement("script");
    // 	script.type = "text/javascript";
    // 	script.setAttribute('id', 'mapTest')
    // 	script.src = `https://webapi.amap.com/maps?v=2.0&key=${that.keyMap}`;
    // 	document.body.appendChild(script);
    // 	that.loop();
    // })()
  },
  watch: {
    show(newVal, oldVal) {
      this.showPlace = newVal;
      this.$nextTick(() => {
        this.loop();
      });
    },
  },
  methods: {
    loop() {
      try {
        // this.initMapWeb();
      } catch (e) {
        console.log(e);
        // setTimeout(()=>this.loop(), 200)
      }
    },

    inputValue(e) {
      if (this.timeId) {
        clearTimeout(this.timeId);
      }

      this.timeId = setTimeout(() => {
        this.getPlaces(this.keyword, "");
        this.timeId = null;
      });
    },

    cancel() {
      this.keyword = "";
      this.list = [];
      if (this.maker) {
        this.marker.setMap(null);
      }
    },

    toCancel() {
      this.showPlace = false;
      this.cancel();
      this.$emit("changeShow", false);
    },

    //完成
    confirm() {
      if (this.checked) {
        let address;
        if (this.checked.addressLocal) {
          address = this.checked.addressLocal;
        } else {
          address = `${this.checked.district}${ this.checked.address ? "-" + this.checked.address : "" }`;
        }

        let location = this.checked.location.split(",");

        this.$emit("placeChoose", {
          address,
          adcode: this.checked.adcode,
          latitude: location[1],
          longitude: location[0],
        });
        this.showPlace = false;
        this.cancel();
      }
    },

    toChecked(obj) {
      this.checked = obj;

      if (this.marker) {
        this.marker.setMap(null);
        let tempArr = obj.location.split(",");
        this.addMarker(tempArr[0], tempArr[1]);
      }
    },

    initMapWeb() {
      let that = this;
     
      let initLocation = this.initLocation || this.defaultLocation;
      console.log("初始化", initLocation)
      let tempArr = initLocation.split(",");
      this.map = new AMap.Map("map-container", {
        center: tempArr,
        zoom: 16,
      });

      this.map.on("click", (e) => {
        if (this.marker) {
          this.marker.setMap(null);
        }
        that.addMarker(e.lnglat.lng, e.lnglat.lat);
        that.getPlaces("", `${e.lnglat.lng}, ${e.lnglat.lat}`);
        that.getNowPlace(`${e.lnglat.lng}, ${e.lnglat.lat}`);
      });

      if (initLocation.length > 0) {
        let tempArr = initLocation.split(",");
        console.log(tempArr);
        that.addMarker(Number(tempArr[0]), Number(tempArr[1]));
        that.getPlaces("", `${tempArr[0]}, ${tempArr[1]}`);
        that.getNowPlace(`${tempArr[0]}, ${tempArr[1]}`);
        return;
      }

      AMap.plugin("AMap.Geolocation", function () {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
          // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
          buttonOffset: new AMap.Pixel(10, 20),
          //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
          zoomToAccuracy: true,
          //  定位按钮的排放位置,  RB表示右下
          buttonPosition: "RB",
        });

        geolocation.getCurrentPosition(function (status, result) {
          console.log(result);
          if (result.info == "SUCCESS" && result.position && result.position) {
            that.addMarker(result.position.lng, result.position.lat);
            that.getPlaces(
              "",
              `${result.position.lng}, ${result.position.lat}`
            );
            that.getNowPlace(`${result.position.lng}, ${result.position.lat}`);
          }
        });
      });
    },

    getNowPlace(location) {
      this.$http
        .get(
          `https://restapi.amap.com/v3/geocode/regeo?key=${this.AMapKeyWeb}&location=${location}`
        )
        .then((res) => {
          let result = res.data;
          if (result.status == "1") {
            let tempObj = result.regeocode;
            this.checked = {
              adcode: tempObj.addressComponent.adcode,
              city: tempObj.addressComponent.city,
              district: tempObj.addressComponent.district,
              location,
              addressLocal: tempObj.formatted_address,
            };
          }
        });
    },

    addMarker(lng, lat) {
      if (!lng || !lat) {
        return;
      }
      var icon = new AMap.Icon({
        // 图标的取图地址
        image:
          "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
        // 图标所用图片大小
        imageSize: new AMap.Size(32, 42),
      });

      console.log(lng, lat);
      this.marker = new AMap.Marker({
        icon,
        position: [lng, lat],
      });

      this.marker.setMap(this.map);
      // this.map.setCenter([lng, lat]);
    },

    getPlaces(keywords, location) {
      this.$http
        .get(
          `https://restapi.amap.com/v3/assistant/inputtips?key=${this.AMapKeyWeb}&keywords=${keywords}&location=${location}`
        )
        .then((res) => {
          let result = res.data;
          if (result.status === "1") {
            this.list = result.tips.filter(
              (item) => item.location && item.location.length > 0
            );
            // console.log(this.list, result.tips)
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.amap-choose {
  position: relative;
  width: 100%;
  height: 600px;
  z-index: 99999;

  .info-content {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .map-box {
    flex: 1;
    height: 100%;
    // background-color: pink;
    #map-container {
      width: 100%;
      height: 100%;
    }
    /deep/ .amap-logo {
      display: none !important;
    }
    /deep/ .amap-copyright {
      display: none !important;
    }
  }

  .content {
    // position: absolute;
    // left: 0;
    // bottom: 0;
    width: 400px;
    border-radius: 6px 6px 0 0;
    height: 100%;
    // width: 100%;
    background: white;
    box-sizing: border-box;
    padding: 12px;
    .search {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      & > div {
        width: 88%;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 4px 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        image {
          width: 30px;
          height: 25px;
        }
        input {
          margin-left: 8px;
          flex: 1;
        }
      }
    }

    .main {
      margin-top: 10px;
      height: calc(100% - 44px);
      overflow-y: auto;
      .choose-item {
        box-sizing: border-box;
        padding: 5px 10px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: left;
        justify-content: space-between;
        cursor: pointer;
      
        .name {
          font-weight: 600;
        }
        .detail {
          color: #999;
        }
        .select-flag{
          font-size:20px;
          font-weight:600;
        }
      }
    }
  }
}
</style>
