<template>
<div>
	<!-- 面包屑导航区 -->
	<el-breadcrumb separator-class="el-icon-arrow-right">
		<el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
		<el-breadcrumb-item>商城管理</el-breadcrumb-item>
		<el-breadcrumb-item>商品列表</el-breadcrumb-item>
	</el-breadcrumb>
	<!-- 卡片视图 -->
	<el-card>
		<el-row :gutter="20">
			<el-col :span="6">
				<el-input placeholder="请输入内容" v-model="queryInfo.query" clearable @clear="getGoodsList">
					<el-button slot="append" icon="el-icon-search" @click="getGoodsList"></el-button>
				</el-input>
			</el-col>
			<el-col :span="4">
				<el-button type="primary" @click="goAddPage">添加商品</el-button>
			</el-col>
		</el-row>
		<!-- 表格数据 -->
		<el-table id="mainTable" :height="tableHeight" :data="goodsList" border stripe>
			<!-- <el-table-column type="index"></el-table-column> -->
			<el-table-column label="商品名称" width="200px" prop="goodsName"></el-table-column>
			<el-table-column label="商品品牌" width="150px" prop="brand"></el-table-column>
			<el-table-column label="商品图片" width="125px">
				<template slot-scope="scope"><img :src="scope.row.image" width="100px" height="100px" /></template>
			</el-table-column>
			<el-table-column label="商品价格(元)" width="120px">
				<template slot-scope="scope">
					{{ $round(scope.row.price) }}
				</template>
			</el-table-column>
			<el-table-column label="商品类别" width="200px">
				<template slot-scope="scope">
					<el-tag type="success" size="mini">{{ scope.row.typeName }}</el-tag>
				</template>
			</el-table-column>
			<el-table-column label="商品规格" width="200px">
				<template slot-scope="scope">
					<el-tag type="success" size="mini">{{
						scope.row.spec
					}}</el-tag>
					<!-- <el-tag type="success" size="mini" v-for="(item, index) in scope.row.attributes" :key="index">{{
              item
            }}</el-tag> -->
				</template>
			</el-table-column>
			<!-- <el-table-column label="最低限购数量" width="200px">
          <template slot-scope="scope">
            <el-tag type="success" size="mini">{{
              scope.row.min
            }}  {{
              scope.row.unit
            }}</el-tag>
          </template>
        </el-table-column> -->
			<!-- </el-table-column> -->
			<el-table-column label="创建时间" width="180px">
				<template slot-scope="scope">{{ scope.row.createtime | dataFormat }}</template>
			</el-table-column>
			<el-table-column label="状态" width="180px">
				<template slot-scope="scope">
					<el-tag type="success" size="mini" v-if="scope.row.GoodsStatus == 1">已上架</el-tag>
					<el-tag type="danger" size="mini" v-else>已下架</el-tag>
				</template>
			</el-table-column>
			<el-table-column label="商品详情">
				<template slot-scope="scope">
					{{ scope.row.description ? scope.row.description.substr(0, 20) + "..." : "--" }}
				</template>
			</el-table-column>
			<el-table-column label="操作" fixed="right" width="300px">
				<template slot-scope="scope">
					<el-button v-if="scope.row.GoodsStatus" type="default" icon="el-icon-down" size="mini"
						@click="updateStatus(scope.row.id, 0)">下架</el-button>
					<el-button v-if="!scope.row.GoodsStatus" type="success" icon="el-icon-up" size="mini"
						@click="updateStatus(scope.row.id, 1)">上架</el-button>
					<el-button type="primary" icon="el-icon-edit" size="mini"
						@click="editInfo(scope.row)">编辑</el-button>
					<el-button type="danger" icon="el-icon-delete" size="mini"
						@click="removeById(scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页区域 -->
		<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
			:current-page="queryInfo.page" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.limit"
			layout="total, sizes, prev, pager, next, jumper" :total="total" background></el-pagination>
	</el-card>

	<el-dialog :title="isEdit ? '修改商品信息' : '添加商品信息'" :visible.sync="editDialogVisible" width="50%" lock-scroll
		:close-on-press-escape="false" :close-on-click-modal="false">
		<AddGoods :detail="currentDetail" :isEdit="isEdit" @refreshList="refreshList" />
	</el-dialog>
</div>
</template>

<script>
import {
	goodsList
} from "@/config/config.js";
import AddGoods from "./Add.vue";
import { myMixins } from "@/mixins/myMixins.js";
export default {
	components: {
		AddGoods
	},
	mixins: [myMixins],
	data() {
		return {
			queryInfo: {
				action: "goodsList",
				query: "",
				page: 1,
				limit: 10,
				type: "-1",
				status: -1,
				path: "ashx/goods.ashx",
			},
			// 商品列表
			goodsList: [],
			// 商品总数
			total: 0,
			editDialogVisible: false,
			isEdit: false,
			currentDetail: null,
			goodsTypeList: [],
			supplierList: []
		}
	},
	created() {
		this.getGoodsList();
		this.getDictionaryList();
		this.getSupplierList();
	},
	methods: {
		refreshList() {
			console.log("更新列表")
			this.getGoodsList();
			this.editDialogVisible = false;
		},
		async getDictionaryList() {
			this.$getDicList(13).then((list) => {
				this.goodsTypeList = list;
			});
		},
		async getSupplierList() {
			const { data: res } = await this.$http.get("openapi/web_proxy_get", {
				params: {
					action: "SupplierList",
					page: 0,
					limit: 100,
					path: "ashxWeb/Web_goods.ashx"
				},
			});
			if (res.code !== 200) {
				return this.$message.error("获取供应商列表失败！");
			}
			this.supplierList = res.data;
		},
		// 根据分页获取对应的商品列表
		async getGoodsList() {
			let params = _.cloneDeep(this.queryInfo);
			params.page--;
			const { data: res } = await this.$http.get("openapi/web_proxy_get", {
				params
			});
			if (res.code !== 200) {
				return this.$message.error("获取商品列表失败！");
			}
			this.goodsList =
				res.data && typeof res.data == "object" ? res.data : supplierList;
			this.total = res.total || this.goodsList.length
		},
		handleSizeChange(newSize) {
			this.queryInfo.limit = newSize
			this.getGoodsList()
		},
		handleCurrentChange(newSize) {
			this.queryInfo.page = newSize
			this.getGoodsList()
		},
		// 通过Id删除商品
		async removeById(id) {
			const confirmResult = await this.$confirm(
				'此操作将永久删除该商品, 是否继续?',
				'提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}
			).catch(err => err)
			if (confirmResult !== 'confirm') {
				return this.$message.info('已取消删除！')
			}
			const {
				data: res
			} = await this.$http.delete('goods/' + id)
			if (res.meta.status !== 200) {
				return this.$message.error('删除商品失败！')
			}
			this.$message.success('删除商品成功！')
			this.getGoodsList()
		},
		goAddPage() {
			// this.$router.push('/goods/add')
			this.isEdit = false;
			this.currentDetail = null;
			this.editDialogVisible = true
		},
		updateStatus(ID, flag) {
			this.$confirm(flag == 1 ? '是否确认上架该商品' : '是否确认下架该商品', '温馨提示', {
				confirmButtonText: '确定',
				callback: async (action) => {
					if (action == 'confirm') {
						const {
							data: res
						} = await this.$http.post("openapi/proxy_post", {
							action: "goodsDel",
							path: "ashxWeb/Web_goods.ashx",
							status: flag,
							id: ID
						});
						if (res.code !== 200) {
							return this.$message.error("操作失败！");
						}
						this.$message.success("操作成功！");
						this.getGoodsList();
					}
				}
			});
		},
		editInfo(current) {
			this.isEdit = true;
			current.name = current.goodsName
			this.currentDetail = current;
			this.editDialogVisible = true
		}
	}
}
</script>

<style lang="less" scoped></style>