<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商城管理</el-breadcrumb-item>
      <el-breadcrumb-item>供货商列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
      <!-- 搜索 添加 -->
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input
            placeholder="请输入内容"
            v-model="queryInfo.query"
            clearable
            @clear="getList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="addDialogVisible = true"
            >添加供货商</el-button
          >
        </el-col>
      </el-row>
      <!-- 用户列表区域 -->
      <el-table :data="list" border stripe>
        <!-- stripe: 斑马条纹
          border：边框-->
        <el-table-column width="50px" prop="id" label="id"></el-table-column>
        <el-table-column
          width="200px"
          prop="supplierName"
          label="供货商名称"
        ></el-table-column>
        <el-table-column
          width="200px"
          prop="name"
          label="联系人姓名"
        ></el-table-column>
        <el-table-column
          width="150px"
          prop="mobile"
          label="联系人手机号"
        ></el-table-column>
        <el-table-column
          width="180px"
          prop="card"
          label="联系人身份证号"
        ></el-table-column>
        <!-- <el-table-column width="200px" label="状态">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status == 1 ? true : false"
              @change="stateChanged(scope.row)"
            ></el-switch>
          </template>
        </el-table-column> -->
        <el-table-column prop="address" label="通讯地址"> </el-table-column>
        <el-table-column fixed="right" width="400px" label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              circle
              @click="showEditDialog(scope.row)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              circle
              @click="removeUserById(scope.row.id)"
            ></el-button>
            <el-button icon="el-icon-location" @click="viewLocation(scope.row)"
              >查看位置</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[2, 5, 10, 15]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>

    <!-- 添加用户的对话框 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClosed"
    >
      <!-- 内容主体 -->
      <el-form
        :model="addForm"
        ref="addFormRef"
        :rules="addFormRules"
        label-width="100px"
      >
        <el-form-item label="供货商名称" width="200px">
          <el-input v-model="addForm.supplierName"></el-input>
        </el-form-item>
        <el-form-item label="联系人姓名" width="200px">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="联系人身份证号" width="200px">
          <el-input v-model="addForm.card"></el-input>
        </el-form-item>
        <el-form-item label="联系人手机号" width="200px">
          <el-input v-model="addForm.mobile"></el-input>
        </el-form-item>
        <el-form-item label="通讯地址" width="200px">
          <el-button type="primary" @click="selectMapAddress"
            >选择通讯坐标地址</el-button
          >
          <div style="display: flex">
            <el-input
              width="200px"
              placeholder="详细地址"
              v-model="addForm.address"
            ></el-input>
            <el-input
              width="200px"
              placeholder="经度，选择后自动获取"
              disabled
              v-model="addForm.lat"
            ></el-input>
            <el-input
              width="200px"
              placeholder="纬度，选择后自动获取"
              disabled
              v-model="addForm.lng"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="备注信息" width="200px">
          <el-input v-model="addForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="mapDialogTitle"
      :visible.sync="addressDialogVisible"
      width="50%"
      @close="addressDialogClosed"
    >
      <AmapChose
        ref="mapChoose"
        :initLocation="initLocation"
        :onlyShowMap="onlyShowMap"
        :show="addressDialogVisible"
        @placeChoose="placeChoose"
        @changeShow="changeShow"
      ></AmapChose>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelChooseAddress">取 消</el-button>
        <el-button type="primary" @click="confirmChooseAddress"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    
  </div>
</template>
<script>
import _ from "lodash";
import upload from "@/components/upload";
import { supplierList } from "@/config/config.js";
import AmapChose from "@/components/amap-choose/amap-choose.vue";
export default {
  data() {
    // 自定义邮箱规则
    var checkEmail = (rule, value, callback) => {
      const regEmail = /^\w+@\w+(\.\w+)+$/;
      if (regEmail.test(value)) {
        // 合法邮箱
        return callback();
      }
      callback(new Error("请输入合法邮箱"));
    };
    // 自定义手机号规则
    var checkMobile = (rule, value, callback) => {
      const regMobile = /^1[34578]\d{9}$/;
      if (regMobile.test(value)) {
        return callback();
      }
      // 返回一个错误提示
      callback(new Error("请输入合法的手机号码"));
    };
    return {
      // 获取用户列表查询参数对象
      queryInfo: {
        action: "SupplierList",
        query: "",
        page: 1,
        limit: 10,
        path: "ashxWeb/Web_goods.ashx",
      },
      list: [],
      total: 0,
      isEditUser: false,
      // 添加用户对话框
      addDialogVisible: false,
      // 用户添加
      addForm: {
        name: "",
        card: "",
        supplierName: "",
        mobile: "",
        address: "",
        lat: "",
        lng: "",
        remark: "",
        password: "",
      },
      // 用户添加表单验证规则
      addFormRules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        address: [
          { required: true, message: "请输入通讯地址", trigger: "blur" },
        ],
        telephone: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
        ],
      },
      currentUser: null,
      initLocation: "",
      addressDialogVisible: false,
      tempAddressInfo: {},
      mapDialogTitle: "选择地址",
      onlyShowMap: false,
    };
  },
  components: {
    upload,
    AmapChose,
  },
  created() {
    this.getList();
  },
  computed: {
    dialogTitle() {
      return this.isEditUser ? "编辑供货商信息" : "添加供货商";
    },
  },
  methods: {
    
    handleUploadSuccess(res) {
      console.log(res);
    },
    async getList() {
      let params = _.cloneDeep(this.queryInfo);
      params.page--;
      const { data: res } = await this.$http.get("openapi/web_proxy_get", {
        params,
      });
      if (res.code !== 200) {
        return this.$message.error("获取供应商列表失败！");
      }
      this.list =
        res.data && typeof res.data == "object" ? res.data : supplierList;
      this.total = res.total || this.list.length;
    },
    // 监听 limit改变的事件
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.queryInfo.limit = newSize;
      this.getList();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      // console.log(newSize)
      this.queryInfo.page = newSize;
      this.getList();
    },
    // 监听 switch开关 状态改变
    async stateChanged(userInfo) {
      const { data: res } = await this.$http.post("openapi/proxy_post", {
        action: "SupplierDel",
        path: "/ashxWeb/Web_goods.ashx",
        id: userInfo.id,
      });
      if (res.code !== 200) {
        userInfo.status = !userInfo.status;
        return this.$message.error("更新状态失败");
      }
      this.$message.success("更新用户成功！");
    },
    // 监听 添加用户对话框的关闭事件
    addDialogClosed() {
      this.$refs.addFormRef.resetFields();
      this.currentUser = null;
    },
    // 添加用户
    addUser() {
      // 提交请求前，表单预验证
      this.$refs.addFormRef.validate(async (valid) => {
        // console.log(valid)
        // 表单预校验失败
        if (!valid) return;
        let params = _.cloneDeep(this.addForm);
        if (this.isEditUser) {
          params.action = "SupplierEdit";
          params.id = this.currentUser.id;
        } else {
          params.action = "SupplierAdd";
        }
        params.path = "ashxWeb/Web_goods.ashx";
        const { data: res } = await this.$http.post(
          "openapi/proxy_post",
          params
        );
        if (res.code !== 200) return this.$message.error("添加供货商失败！");
        this.$message.success("添加供货商成功!");
        this.addDialogVisible = false;
        this.getList();
      });
    },
    // 编辑用户信息
    async showEditDialog(row) {
      this.isEditUser = true;
      this.currentUser = row;
      let _newObj = _.cloneDeep(row);
      for (let key in this.addForm) {
        this.addForm[key] = _newObj[key];
      }
      this.addDialogVisible = true;
    },
    // 删除用户
    async removeUserById(id) {
      const confirmResult = await this.$confirm(
        "此操作将永久删除该用户, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("users/" + id);
      if (res.meta.status !== 200) return this.$message.error("删除用户失败！");
      this.$message.success("删除用户成功！");
      this.getList();
    },
    changeShow() {},
    placeChoose(result) {
      console.log(result);
      if (result) {
        this.addForm.address = result.address;
        this.addForm.lat = result.latitude;
        this.addForm.lng = result.longitude;
      }
    },
    cancelChooseAddress() {
      this.addressDialogVisible = false;
    },
    confirmChooseAddress() {
      this.$refs["mapChoose"].confirm();
      this.addressDialogVisible = false;
    },
    addressDialogClosed() {
      this.addressDialogVisible = false;
    },
    viewLocation(row) {
      this.initLocation = `${row.lng}, ${row.lat}`;
      this.mapDialogTitle = "查看位置";
      this.onlyShowMap = true;
      this.addressDialogVisible = true;
    },
    selectMapAddress() {
      this.mapDialogTitle = "选择坐标位置";
      this.onlyShowMap = false;
      this.addressDialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped></style>
