<template>
  <div class="service-order-detail">
    <el-descriptions
      class="margin-top"
      title="基本信息"
      :column="3"
      :size="size"
      border
    >
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          订单编号
        </template>
        {{ orderDetail ? orderDetail.OrderNo : '--' }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-mobile-phone"></i>
          订单状态
        </template>
        {{ orderDetail ? getOrderStatus(orderDetail.status) : '--' }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-location-outline"></i>
          下单时间
        </template>
        {{ orderDetail.createtime | dataFormat }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-location-outline"></i>
          期望服务时间
        </template>
        {{
          `${orderDetail.startDate || "不限"}-${orderDetail.EndDate || "不限"}`
        }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          期望服务地址
        </template>
        <el-tag size="small">{{ orderDetail.address }}</el-tag>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-office-building"></i>
          备注
        </template>
        {{ orderDetail.remark }}
      </el-descriptions-item>
    </el-descriptions>

    <el-descriptions
      class="margin-top"
      title="用户信息"
      :column="3"
      :size="size"
      border
      style="margin-top: 20px"
    >
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          姓名
        </template>
        {{ userInfo.name ? userInfo.name : "--" }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-mobile-phone"></i>
          联系电话
        </template>
        {{ userInfo.mobile ? userInfo.mobile : "--" }}
      </el-descriptions-item>
    </el-descriptions>

    <el-descriptions
      class="margin-top"
      title="支付信息"
      :column="3"
      :size="size"
      border
      style="margin-top: 20px"
    >
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          押金应付金额(元)
        </template>
        {{ orderDetail.depositAmount ? $round(orderDetail.depositAmount) : "0" }}
      </el-descriptions-item>
      <!-- <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-mobile-phone"></i>
          押金金额已付(元)
        </template>
        {{ orderDetail.PaidAmount || "--" }}
      </el-descriptions-item> -->
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          总已付金额(元)
        </template>
        {{ orderDetail.amountPaid ? $round( orderDetail.amountPaid) : "0" }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          总应付金额(元)
        </template>
        {{ orderDetail.totalAmount ? $round(orderDetail.totalAmount) : "0" }}
      </el-descriptions-item>
      <!-- <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-mobile-phone"></i>
          实际支付总金额(元)
        </template>
        {{ orderDetail.payedAmount  ||  "0"}}
      </el-descriptions-item> -->
    </el-descriptions>

    <div class="se-table-header">
      <div class="se-title">预约的服务</div>
      <div class="se-buttons">
        <el-button type="primary" @click="progressDialogVisible = true"
          >服务进度</el-button
        >
      </div>
    </div>
    <el-table :data="serviceList" border stripe>
      <el-table-column width="125px" label="服务图片">
        <template slot-scope="scope"
          ><img :src="scope.row.image" width="100px" height="100px"
        /></template>
      </el-table-column>
      <el-table-column
        width="150px"
        label="服务名称"
        prop="name"
      ></el-table-column>
      <!-- <el-table-column width="150px" label="服务类型" prop="service_type">
        <template slot-scope="scope">
          <el-tag type="success">{{ scope.row.serviceTypeName }}</el-tag>
        </template>
      </el-table-column> -->
      <!-- <el-table-column width="150px" label="服务品种">
        <template slot-scope="scope">
          <el-tag >{{ scope.row.variety }}</el-tag>
        </template>
      </el-table-column> -->
      <el-table-column width="200px" label="农机手">
        <template slot-scope="scope">
          <el-tag type="info" icon="el-icon-location">{{
            scope.row.driver
              ? scope.row.driver.name + `(${scope.row.driver.mobile})`
              : "未分配"
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="150px" label="农机">
        <template slot-scope="scope">
          <el-tag type="info">{{
            scope.row.machine ? scope.row.machine.name : "未分配"
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="150px" label="服务状态">
        <template slot-scope="scope">
          {{ getServiceStatus(scope.row.status) }}
        </template>
      </el-table-column>
      <el-table-column
        width="150px"
        label="服务单价(元/亩)"
      >
      <template slot-scope="scope">
        {{ $round(scope.row.price) }}
        </template>
    </el-table-column>
      <el-table-column width="150px" label="累计服务面积">
        <template slot-scope="scope">
          {{ scope.row.totalArea || "--" }}
        </template>
      </el-table-column>
      <el-table-column label="服务总价(元)">
        <template slot-scope="scope">
          {{
            scope.row.totalArea
              ? Number(scope.row.totalArea) * Number($round(scope.row.price))
              : 0
          }}
        </template>
      </el-table-column>

      <el-table-column label="操作" width="300px" fixed="right">
        <template slot-scope="scope">
          <el-button
            type="success"
            size="mini"
            icon="el-icon-location"
            @click="viewStack(scope.row)"
          >
            路径轨迹
          </el-button>
          <el-button
            type="success"
            size="mini"
            icon="el-icon-video-camera"
            @click="viewVideo(scope.row)"
          >
            实时监控
          </el-button>
          <!-- <el-button
            v-if="scope.row.status == 0"
            type="success"
            size="mini"
            icon="el-icon-right"
            @click="intelligentAllocate(scope.row)"
          >
            智能分配
          </el-button> -->
          <el-button
            v-if="scope.row.status == 0"
            type="success"
            size="mini"
            icon="el-icon-right"
            @click="manualAllocate(scope.row)"
          >
            分配农机手
          </el-button>
          <!-- <el-button
            v-if="scope.row.status == 0"
            type="success"
            size="mini"
            icon="el-icon-right"
            @click="manualAllocate(scope.row.id)"
          >
            修改分配
          </el-button> -->
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="查看可分配农机"
      :visible.sync="allocateDialogVisible"
      append-to-body
      modal-append-to-body
      width="90%"
    >
      <AllocateMachine
        :list="machines"
        @dispatchAction="dispatchAction"
      ></AllocateMachine>
    </el-dialog>

    <el-dialog
      title="查看服务进度"
      :visible.sync="progressDialogVisible"
      @close="progressDialogClose"
      append-to-body
      modal-append-to-body
      width="80%"
    >
      <ServiceProgress :orderNo="OrderNo"></ServiceProgress>
    </el-dialog>

    <el-dialog title="查看轨迹" :visible.sync="trajectoryVisible"   
      append-to-body
      modal-append-to-body 
    width="80%">
      <Trajectory :trajectPathList="trajectPathList" />
    </el-dialog>
  </div>
</template>

<script>
import AllocateMachine from "./AllocateMachine.vue";
import ServiceProgress from "./ServiceProgress.vue";
import { serviceOrderStatus, serviceStatus } from "@/config/index.js";
import { machines } from "@/config/config.js";
import { recursiveJsonParse } from "@/common/util.js";
import Trajectory from "@/components/trajectory/Index.vue";
export default {
  props: {
    OrderNo: {
      type: String,
      default: "",
    },
  },
  components: {
    AllocateMachine,
    ServiceProgress,
    
    Trajectory,
  },
  data() {
    return {
      size: "lg",
      machines: [],
      allocateDialogVisible: false,
      progressDialogVisible: false,
      isAppend: true,
      orderDetail: {},
      serviceList: [],
      currentService: null,
      userInfo:{},
	    serviceTypeList:[],
      trajectoryVisible: false,
      trajectPathList: [],
    };
  },
  watch: {
    OrderNo: {
      immediate: true,
      handler(nval) {
        if (nval) {
          this.getOrderDetail();
          // this.getOrderServiceList();
        }
      },
    },
  },
  created(){
	this.getDictionaryList();
  },
  methods: {
    async getDictionaryList() {
      this.$getDicList(1).then((list) => {
        this.serviceTypeList = list;
      });
      this.$getDicList(2).then((list) => {
        this.serviceVarietyList = list;
      });
      this.$getDicList(3).then((list) => {
        this.categoryList = list;
      });
      console.log("获取服务");
    },
    async getOrderDetail() {
      try{
        const { data: res } = await this.$http.get("web_api/machine_service/order_detail", {
        params: {
          // action: "OrderShow",
          // path: "ashxWeb/Servicesorder.ashx",
          OrderNo: this.OrderNo,
        },
      });
      if (res.code !== 200) {
        return this.$message.error("获取订单详情失败！");
      }
      console.log("订单详情");
      this.orderDetail = res.data || {};
      this.serviceList = this.orderDetail.serviceList ? recursiveJsonParse(this.orderDetail.serviceList) : [];
      console.log(this.serviceList)
      this.userInfo = this.orderDetail.user ? recursiveJsonParse(this.orderDetail.user) : {};
      }catch(err){
         console.log(err);
      }
      
    },
    async getOrderServiceList() {
      const { data: res } = await this.$http.get("openapi/web_proxy_get", {
        params: {
          action: "OrderByOrderNo",
          path: "ashxWeb/Servicesorder.ashx",
          orderNo: this.OrderNo,
        },
      });
      if (res.code !== 200) {
        return this.$message.error("获取订单服务列表失败！");
      }
      console.log(res.data);
      this.serviceList = res.data;
    },
    getOrderStatus(status) {
      return serviceOrderStatus[status];
    },
    getServiceStatus(status) {
      console.log("status", status);
      if (status != "") {
        return serviceStatus[status];
      }
    },
    intelligentAllocate() {},
    manualAllocate(row) {
      this.currentService = row;
      this.allocateDialogVisible = true;
    },
    allocateDialogClose() {
      this.currentService = null;
      this.allocateDialogVisible = false;
    },
    progressDialogClose(){
      this.currentService = null;
      this.progressDialogVisible = false;
    },
    viewVideo() {},
    async dispatchAction(machineId) {
      console.log(this.currentService)
      const { data: res } = await this.$http.post("openapi/proxy_post", {
        path: "ashxWeb/Servicesorder.ashx",
        action: "OrderDispatch",
        // OrderNo: this.currentService.OrderNo,
        serviceId: this.currentService.serviceId,
        machineId,
      });
      if (res.code !== 200 || res.data[0].code == -1)
        return this.$message.error(res.data[0].msg || "派单失败！");
      this.$message.success("派单成功!");
      this.allocateDialogVisible = false;
      this.getOrderServiceList()
    },
    viewStack() {
      this.trajectoryVisible = true;
      function generateIrregularArea(center, count, maxRadius) {
        const areaPoints = [];
        const twoPi = 2 * Math.PI;

        for (let i = 0; i < count; i++) {
          // 随机生成角度和距离
          const angle = twoPi * Math.random();
          const distance = maxRadius * Math.random();

          // 计算点的坐标
          const point = [
            center[0] + distance * Math.cos(angle),
            center[1] + distance * Math.sin(angle),
          ];

          // 将点添加到数组中
          areaPoints.push(point);
        }

        return areaPoints;
      }

      // 中心点坐标
      const center = [116.768687, 32.578138];
      // 要生成的点的数量
      const count = 10;
      // 最大半径
      const maxRadius = 0.008;

      // 生成不规则区域坐标数组
      const irregularArea = generateIrregularArea(center, count, maxRadius);
      console.log(irregularArea);
      setTimeout(() => {
        console.log("-----------------------------");
        this.trajectPathList = irregularArea
        // [
        //   // [经度，纬度] 可再次放置测试数据
        //   [120.79573938, 30.03576463],
        //   [120.79573938, 30.04676463],
        //   [120.80573938, 30.04676463],
        //   [120.80573938, 30.03576463],
        //   [120.79573938, 30.03576463],
        // ];
      }, 1000);
    },
  },
};
</script>
<style lang="less" scoped>
.service-order-detail {
  .se-table-header {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    .se-title {
      font-size: 16px;
      font-weight: 700;
    }

    .se-buttons {
    }
  }
}
</style>
