<template>
  <div>
    <!-- 卡片视图 -->
    <el-card>
      <!-- 提示 -->
      <el-alert
        title="添加土地板块信息"
        type="info"
        center
        show-icon
        :closable="false"
      ></el-alert>

      <!-- Tab栏 -->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="100px"
        label-position="top"
      >
        <el-form-item label="土地板块名称">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="上级板块">
          <el-select
            v-model="addForm.parent_id"
            @change="parentChange"
            placeholder="请选择上级板块"
          >
            <!-- <el-option label="请选择上级板块" value=""> </el-option> -->
            <el-option label="根板块" :value="0"> </el-option>
            <el-option
              v-for="item in parentList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="板块总面积（亩）">
          <el-input type="number" v-model="addForm.area"></el-input>
        </el-form-item>
        <el-form-item label="经纬度坐标">
          <el-col class="line" :span="1" style="text-align: center"
            >经度：</el-col
          >
          <el-col :span="6">
            <el-input
              placeholder="经度坐标"
              v-model="addForm.longitude"
            ></el-input>
          </el-col>
          <el-col class="line" :span="1" style="text-align: center"
            >纬度：</el-col
          >
          <el-col :span="6">
            <el-input
              placeholder="纬度坐标"
              v-model="addForm.latitude"
            ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="板块预览图片">
          <upload
            name="image"
            :preview="false"
            :initFileList="initFileList"
            @handleUploadSuccess="handleUploadSuccess"
            @handleRemove="handleRemove"
          ></upload>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="addForm.remark"></el-input>
        </el-form-item>
        <el-button
          type="primary"
          class="btnAdd"
          style="width: 100%"
          @click="addLand"
          >添加土地板块</el-button
        >
      </el-form>
    </el-card>
  </div>
</template>

<script>
import _ from "lodash";
import upload from "@/components/upload";
import { Base64 } from "js-base64";
export default {
  components: {
    upload,
  },
  props: {
    detail: Object,
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 添加资讯的表单对象
      addForm: {
        name: "",
        area: "",
        longitude: "",
        latitude: "",
        remark: "",
        parent_id: "",
        image: "",
        level: "",
      },
      addFormRules: {
        title: [
          {
            required: true,
            message: "请输入板块名称",
            trigger: "blur",
          },
        ],
        area: [
          {
            required: true,
            message: "请输入板块面积",
            trigger: "blur",
          },
        ],
        parent_id: [
          {
            required: true,
            message: "请选择上级板块",
            trigger: "blur",
          },
        ],
      },
      initFileList: [],
    };
  },
  created() {},
  watch: {
    detail: {
      handler(nval, oval) {
        if (nval) {
          let _newObj = _.cloneDeep(nval);

          for (let key in this.addForm) {
            this.addForm[key] = _newObj[key];
          }
          if (this.addForm.image) {
            this.initFileList = [
              {
                url: this.addForm.image,
                name: this.addForm.image,
              },
            ];
          } else {
            this.initFileList = [];
          }
        } else {
          this.$nextTick(() => {
            console.log("重置");
            this.$refs["addFormRef"].resetFields();
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    parentList() {
      return this.$parent.$parent.landList;
    },
  },
  methods: {
    handleRemove() {
      this.addForm.image = "";
    },
    // 监听图片上传成功事件
    handleUploadSuccess(response) {
      this.addForm.image = response.value[0].url;
    },
    // 添加资讯
    addLand() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) return this.$message.error("请填写必要的表单项！");
        let params = _.cloneDeep(this.addForm);
        let url;
        if (this.isEdit) {
          url = "web_api/landLease/updateLand";
          params.id = this.detail.id;
        } else {
          url = "web_api/landLease/addLand";
        }
        const { data: res } = await this.$http.post(url, params);
        if (res.code !== 200) return this.$message.error("添加板块失败！");
        this.$message.success("添加板块成功!");
        this.$emit("refreshList");
      });
    },
    clearForm() {
      console.log("---------------------------");
      this.$refs["addFormRef"].resetFields();
    },
    parentChange(ev) {
      console.log(ev);
      if (ev == 0) {
        this.addForm.level = 1;
      } else {
        let pItem = this.parentList.find((item) => item.id == ev);
        this.addForm.level = pItem.level + 1;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-checkbox {
  margin: 0 8px 0 0 !important;
}

.previewImg {
  width: 100%;
}

.btnAdd {
  margin-top: 15px;
}
</style>
