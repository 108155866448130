// const machineStatus =  { '1': '闲置中', '2': '服务中', '-99': '已下架', '9': '待审核', '-9': '已离线' };

const machineStatus = {
	'1': '闲置中',
	'2': '服务中',
	'-99': '已下架'
};

const serviceOrderStatus = {
	0: '押金待支付',
	1: '待平台确认',
	2: '服务中',
	3: '待支付尾款',
	4: '已完成',
	'-99': '已取消'
};

const serviceStatus = {
	0: '待派单',
	1: '待农机手确认',
	2: '服务中',
	3: '已完成',
	'-99': '已取消'
};

const shopOrderStatus = {
	0: '待确认',
	1: '待配送',
	2: '配送中',
	3: '已配送待支付',
	4: '已支付',
	'-99': '已取消'
};

const farmOrderStatus = {
	0: '待支付', 
	1: '待确认', 
	2: '待配送', 
	3: '收货待付款',  
	4: '已完成',
	'-99': '已取消'
};

const canteenOrderStatus = {
	0: '待支付',
	1: '待配送',
	2: '配送中',
	3: '已完成',
	'-99': '已取消'
};


const platform = [{
	name: "平台",
	value: "0"
}, {
	name: "第三方",
	value: "1"
}];

const adminPath = "api/private/v1/";

const proxyPath = "openapi";


const refundStatus = {
	0: '退款中',
	1: '部分退款',
	2: '已全额退款',
	'-1': '退款失败',
	'-99': '已取消'
};


export {
	machineStatus,
	serviceOrderStatus,
	serviceStatus,
	shopOrderStatus,
	farmOrderStatus,
	adminPath,
	proxyPath,
	platform,
	canteenOrderStatus,
	refundStatus
}