<template>
	<div>
		<!-- 面包屑导航区 -->
		<!-- <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加商品</el-breadcrumb-item>
    </el-breadcrumb> -->
		<!-- 卡片视图 -->
		<el-card>
			<!-- 提示 -->
			<el-alert title="添加商品信息" type="info" center show-icon :closable="false"></el-alert>
			<el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px" label-position="top">
				<el-form-item label="商品名称">
					<el-input v-model="addForm.name"></el-input>
				</el-form-item>

				<el-form-item label="商品品牌">
					<el-input v-model="addForm.brand"></el-input>
				</el-form-item>
				<el-form-item label="商品规格">
					<el-input v-model="addForm.spec"></el-input>
				</el-form-item>
				<el-form-item label="商品单价">
					<el-input v-model="addForm.price"></el-input>
				</el-form-item>
				<el-form-item label="商品计量单位" prop="unit">
					<el-input class="form-item-input" v-model="addForm.unit" placeholder="在此输入商品计量单位,例如件,袋,瓶"
						type="text"></el-input>
				</el-form-item>
				<el-form-item label="商品分类">
					<el-select v-model="addForm.type" placeholder="请选择商品分类">
						<el-option v-for="item in goodsTypeList" :key="item.value" :label="item.name" :value="item.value">
						</el-option>
					</el-select>
					<!-- <el-cascader v-model="addForm.category" :options="cateList" :props="cascaderProps"
						@change="handleChange"></el-cascader> -->
				</el-form-item>
				<!-- <el-form-item label="商品规格">
					<el-select v-model="addForm.attribute" filterable allow-create default-first-option
						placeholder="请选择商品规格">
						<el-option v-for="item in attributeList" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item> -->
				<el-form-item label="供货商">
					<el-select v-model="addForm.supplierId" placeholder="请选择供货商">
						<el-option v-for="item in supplierList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="商品图片">
					<upload name="goods_image" :initFileList="initFileList"	@handleUploadSuccess="handleUploadSuccess" @handleRemove="handleRemove"></upload>
				</el-form-item>
				<el-form-item label="商品内容介绍">
					<el-input type="textarea" :autosize="{ minRows: 5, maxRows: 20}" placeholder="商品内容介绍"	v-model="addForm.description">
					</el-input>
				</el-form-item>
				<!-- <el-form-item label="商品详情">
          <upload
            style="opacity: 0"
            name="description_image"
            @handleUploadSuccess="handleUploadSuccess"
          ></upload>
          <quill-editor
            ref="goods_editor"
            v-model="addForm.description"
          ></quill-editor>          
        </el-form-item> -->
				<el-button type="primary" class="btnAdd" @click="addAction">添加商品</el-button>
			</el-form>
		</el-card>
	</div>
</template>

<script>
	import _ from "lodash";
	import {
		attributeList,
		categoryList
	} from "@/config/config.js";
	import upload from "@/components/upload";
	export default {
		components: {
			upload,
		},
		props: {
			detail: Object,
			isEdit: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			// goodsTypeList() {
			// 	return this.$parent.$parent.goodsTypeList;
			// }
			supplierList() {
				return this.$parent.$parent.supplierList;
			}
		},
		data() {
			return {
				addForm: {
					name: "",
					type: "",
					brand: "",
					spec: "",
					image: "",
					unit:"",
					description: "",
					supplierId: "",
					price: ""
				},
				goodsTypeList: [
					{
						name: '种子',
						value: "1"
					},
					{
						name: '农药',
						value: "2"
					},{
						name: '肥料',
						value: "3"
					}
				],
				initFileList: [],
				addFormRules: {
					name: [{
						required: true,
						message: "请输入商品名称",
						trigger: "blur",
					}],
					type: [{
						required: true,
						message: "请选择商品分类",
						trigger: "blur",
					}],
					spec: [{
						required: true,
						message: "请输入商品规格",
						trigger: "blur",
					}],
					brand: [{
						required: true,
						message: "请输入商品品牌",
						trigger: "blur",
					}],
					image: [{
						required: true,
						message: "请上传商品照片",
						trigger: "blur",
					}],
					description: [{
						required: true,
						message: "请填写商品介绍",
						trigger: "blur",
					}],
					supplier: [{
						required: true,
						message: "请选择供货商",
						trigger: "blur",
					}],
				},
			};
		},
		watch: {
			detail: {
				handler(nval, oval) {
					if (nval) {
						nval.type = nval.type ? "" + nval.type : null;
						let _newObj = _.cloneDeep(nval);
						_newObj.price = this.$round(_newObj.price);
						for (let key in this.addForm) {
							this.addForm[key] = _newObj[key];
						}
						if (this.addForm.image) {
							this.initFileList = [{
								url: this.addForm.image
							}];
						} else {
							this.initFileList = [];
						}
					} else {

					}
				},
				deep: true,
				immediate: true
			}
		},
		created() {
			
		},
		methods: {
			// 监听图片上传成功事件
			handleRemove() {
				this.addForm.image = ""
			},
			// 监听图片上传成功事件
			handleUploadSuccess(response) {
				if (response && response.name == "goods_image") {
					this.addForm.image = response.value[0].url;
				}
			},
			// 添加商品
			addAction() {
				this.$refs.addFormRef.validate(async (valid) => {
					if (!valid) return this.$message.error("请填写必要的表单项！");
					// 发送请求前：需对提交的表单进行处理goods_cat attrs
					// this.addForm.goods_cat = this.addForm.goods_cat.join(',')
					// 以上写法不对：级联选择器绑定的对象goods_cat要求是数组对象
					// 解决办法: 包：lodash 方法（深拷贝）：cloneDeep(boj)

					let params = _.cloneDeep(this.addForm)
					params.type = Number(params.type);
					params.price = params.price * 100;
					if (this.isEdit) {
						params.action = "goodsUpdate"
						params.id = this.detail.id
					} else {
						params.action = "goodsAdd";
					}
					params.path = "ashxWeb/Web_goods.ashx"
					const {
						data: res
					} = await this.$http.post("/openapi/proxy_post", params);
					if (res.code !== 200)
						return this.$message.error("添加商品失败！");
					this.$message.success("添加商品成功!");
					// this.$router.push("/service");
					this.$emit("refreshList");
				});
			},
		},
	};
</script>

<style lang="less" scoped>
	.el-checkbox {
		margin: 0 8px 0 0 !important;
	}

	.previewImg {
		width: 100%;
	}

	.btnAdd {
		margin-top: 15px;
	}
</style>