<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>土地租赁管理</el-breadcrumb-item>
      <el-breadcrumb-item>土地列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
      <!-- 搜索 添加 -->
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input
            placeholder="请输入内容"
            v-model="queryInfo.query"
            clearable
            @clear="getList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="goAddPage">添加土地板块</el-button>
        </el-col>
      </el-row>
      <!-- 用户列表区域 -->
      <el-table
        :data="landList"
        style="width: 100%"
        row-key="id"
        border
        lazy
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column width="300px" label="名称">
          <template slot-scope="scope">
            <el-tag type="default" class="bold" size="mini">{{
              scope.row.name
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="300px" prop="area" label="板块总面积(亩)">
          <template slot-scope="scope">
            {{ Number(scope.row.area).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column width="300px" prop="leased" label="已租赁面积(亩)">
          <template slot-scope="scope">
            {{ Number(scope.row.leased).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column width="300px" prop="area" label="剩余可租面积(亩)">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.area - scope.row.leased > 0"
              type="success"
              size="mini"
              >{{
                Number(scope.row.area - scope.row.leased).toFixed(2)
              }}</el-tag
            >
            <el-tag
              v-if="scope.row.area - scope.row.leased == 0"
              type="danger"
              size="mini"
              >{{
                Number(scope.row.area - scope.row.leased).toFixed(2)
              }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column width="300px" label="板块经纬度坐标">
          <template slot-scope="scope">
            {{ `${scope.row.longitude || ""}, ${scope.row.latitude || ""}` }}
          </template>
        </el-table-column>
        <el-table-column width="200px" label="是否公示">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.state"
              @change="userStateChanged(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column width="200px" label="备注" prop="remark">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="editInfo(scope.row)"
              >编辑</el-button
            >
            <el-button
              v-if="
                scope.row.level == 2 && scope.row.area - scope.row.leased > 0
              "
              type="success"
              icon="el-icon-add"
              size="mini"
              @click="addLandLease(scope.row)"
              >发起租赁</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[2, 5, 10, 15]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>

    <el-dialog
      :title="isEdit ? '修改土地板块' : '添加土地板块'"
      :visible.sync="editDialogVisible"
      width="50%"
    >
      <AddLand
        ref="addLand"
        :detail="currentDetail"
        :isEdit="isEdit"
        @refreshList="refreshList"
        @close="editDialogVisible = false"
      />
    </el-dialog>

    <el-dialog
      title="添加租赁"
      :visible.sync="addLeaseRecordDialogVisible"
      width="40%"
    >
      <el-form
        :model="landLeaseForm"
        ref="updateProgressFormRef"
        label-width="200px"
      >
        <el-form-item label="租赁板块">
          <el-input
            disabled="disabled"
            :value="currentDetail ? currentDetail.name : ''"
            placeholder="在此输入租赁面积"
          ></el-input>
        </el-form-item>
        <el-form-item label="租赁面积(亩)">
          <el-input
            v-model="landLeaseForm.area"
            placeholder="在此输入租赁面积"
          ></el-input>
        </el-form-item>
        <el-form-item label="租赁时间段">
          <el-date-picker
            v-model="leaseDateRange"
            align="right"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="租赁合同/凭证">
          <upload
            name="lease_proof_image"
            :initFileList="initFileList"
            :multiple="isMultiple"
            @handleUploadSuccess="handleUploadSuccess"
            @handleRemove="handleRemove"
          ></upload>
        </el-form-item>
        <el-form-item label="租赁人姓名">
          <el-input
            v-model="landLeaseForm.leaser_name"
            placeholder="在此输入租赁人姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="租赁人联系方式">
          <el-input
            v-model="landLeaseForm.leaser_mobile"
            placeholder="在此输入租赁人联系方式"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="landLeaseForm.remark"
            placeholder="在此输入备注信息"
          ></el-input>
        </el-form-item>
        <el-button
          style="width: 300px; margin: 0 auto; margin-left: 200px"
          type="primary"
          @click="addLeaseRecordConfirm"
          >确 定</el-button
        >
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { landList } from "@/config/config.js";
import AddLand from "./Add.vue";
import upload from "@/components/upload";
import _ from "lodash";
export default {
  components: {
    AddLand,
    upload,
  },
  data() {
    return {
      queryInfo: {
        // action: "newsList",
        query: "",
        page: 1,
        limit: 10,
        // path: "ashxWeb/news.ashx",
      },
      // 资讯列表
      landList: [],
      // 资讯总数
      total: 0,
      currentDetail: null,
      isEdit: false,
      editDialogVisible: false,
      addLeaseRecordDialogVisible: false,
      leaseDateRange: "",
      landLeaseForm: {
        land_id: null,
        area: "",
        leaser_name: "",
        leaser_mobile: "",
        contract: "",
        remark: "",
      },
      initFileList: [],
      leaseImages: [],
      isMultiple: true,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    refreshList() {
      console.log("更新列表");
      this.getList();
      this.editDialogVisible = false;
    },
    // 根据分页获取对应的资讯列表
    async getList() {
      // this.landList = landList;
      // this.total = landList.length;
      // return;

      let params = _.cloneDeep(this.queryInfo);
      params.page--;
      const { data: res } = await this.$http.get("web_api/landLease/lands", {
        params,
      });
      if (res.code !== 200) {
        return this.$message.error("获取土地列表失败！");
      }
      this.landList = res.data;
      this.total = res.total || this.landList.length;
    },
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.getList();
    },
    handleCurrentChange(newSize) {
      this.queryInfo.page = newSize;
      this.getList();
    },
    // 通过Id删除资讯
    async removeById(id) {
      this.$confirm("是否删除该资讯", "温馨提示", {
        confirmButtonText: "确定",
        callback: async (action) => {
          if (action == "confirm") {
            // const { data: res } = await this.$http.post("openapi/proxy_post", {
            //   action: "news_delete",
            //   path: "ashxWeb/news.ashx",
            //   id,
            // });
            // if (res.code !== 200) {
            //   return this.$message.error("操作失败！");
            // }
            // this.$message.success("操作成功！");
            // this.getList();
          }
        },
      });
    },
    goAddPage() {
      // this.$router.push('/goods/add')
      this.isEdit = false;
      setTimeout(() => {
        this.currentDetail = null;
        this.editDialogVisible = true;
      }, 100);
    },
    async userStateChanged(currentDetail) {
      let params = _.cloneDeep(currentDetail);
      params.state = params.state == true ? 1 : 0;
      const { data: res } = await this.$http.post(
        "web_api/landLease/updateLandState",
        params
      );
      if (res.code !== 200) return this.$message.error("更改状态失败！");
      this.$message.success("更改状态成功!");
      this.refreshList();
    },
    editInfo(current) {
      this.isEdit = true;
      setTimeout(() => {
        this.currentDetail = current;
        this.editDialogVisible = true;
      }, 100);
    },
    closeDialog() {
      this.isEdit = false;
      this.currentDetail = null;
      this.editDialogVisible = false;
      this.refs['addLand'].clearForm();
    },
    handleRemove(response) {
      if (response && response.name == "lease_proof_image") {
        console.log(response);
        this.leaseImages = response.value.map((item) => {
          return item.url;
        });
      }
    },
    // 监听图片上传成功事件
    handleUploadSuccess(response) {
      if (response && response.name == "lease_proof_image") {
        this.leaseImages = response.value.map((item) => {
          return item.url;
        });
      }
    },
    addLandLease(current) {
      this.currentDetail = current;
      this.addLeaseRecordDialogVisible = true;
    },
    async addLeaseRecordConfirm() {
      if (!this.leaseImages.length) {
        return this.$message.error("租赁合同不能为空");
      }

      if ( this.landLeaseForm.area > (this.currentDetail.area - this.currentDetail.leased) ) {
        return this.$message.error("租赁面积不能大于剩余可租赁面积");
      }
      let params = _.cloneDeep(this.landLeaseForm);
      params.land_id = this.currentDetail.id;
      params.lease_start = new Date(this.leaseDateRange[0]).Format(
        "yyyy-MM-dd"
      );
      params.lease_end = new Date(this.leaseDateRange[1]).Format("yyyy-MM-dd");
      params.contract = this.leaseImages ? this.leaseImages.join(",") : "";
      params.remark = params.remark || "无";
      const { data: res } = await this.$http.post(
        "web_api/landLease/addLeaseRecord",
        params
      );
      if (res.code !== 200) return this.$message.error("发起租赁失败！");
      this.$message.success("发起租赁成功!");
      this.refreshList();
      this.currentDetail = null;
      this.addLeaseRecordDialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped></style>
