<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>服务订单列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
      <el-row>
        <el-col :span="6">
          <el-input placeholder="请输入内容">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
      </el-row>

      <!-- 订单列表 -->
      <el-table id="mainTable" :height="tableHeight"  :data="list" border stripe>
        <!-- <el-table-column type="index" label="#"></el-table-column> -->
        <el-table-column
          label="订单编号"
          width="220px"
          prop="OrderNo"
        ></el-table-column>
        <!-- <el-table-column width="200px" label="服务图片">
          <template slot-scope="scope">
            <img :src="scope.row.service.image" width="180px" height="auto" />
          </template>
        </el-table-column> -->
        <!-- <el-table-column width="200px" label="服务">
          <template slot-scope="scope">
            <el-tag
              v-for="(item, index) in scope.row.services"
              :key="index"
              type="success"
              >{{ item.type }}</el-tag
            >
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="是否付款" width="100px">
					<template slot-scope="scope">
						<el-tag type="success" size="mini" v-if="scope.row.isPayed">已付款</el-tag>
						<el-tag type="danger" size="mini" v-else>未付款</el-tag>
					</template>
				</el-table-column> -->
        <el-table-column label="当前状态" width="120px">
          <template slot-scope="scope">
            <el-tag type="primary" size="mini">{{
              getStatus(scope.row.status)
            }}</el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column width="100px" label="服务类型">
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.service.type }}</el-tag>
          </template>
        </el-table-column> -->
        <!-- <el-table-column width="100px" label="服务品种" prop="service_variety">
          <template slot-scope="scope"
            ><el-tag v-for="item in scope.row.service.variety">{{
              item
            }}</el-tag></template
          >
        </el-table-column> -->
        <el-table-column
          label="下单时间"
          width="220px"
          prop="createtime"
        >
        <template slot-scope="scope">{{
            scope.row.createtime | dataFormat
          }}</template>
      </el-table-column>
       
        <el-table-column width="400px" label="服务地址">
          <template slot-scope="scope">
            <el-button
              type="info"
              size="mini"
              icon="el-icon-location"
              @click="openLocation"
            >
              {{ scope.row.address }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column width="260px" label="期望服务时间">
          <template slot-scope="scope">
            <el-tag type="success">{{
              `${scope.row.startDate  || "不限"}-${scope.row.EndDate || "不限"}`
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="100px" label="下单人">
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.name }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="100px" label="预计面积(亩)">
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.number }}</el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column width="100px" label="预计费用(元)">
          <template slot-scope="scope">
            <el-tag type="success">{{ $round(scope.row.estimateTotal) }}</el-tag>
          </template>
        </el-table-column> -->
        <!-- <el-table-column width="100px" label="实际面积(亩)">
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.actualArea || "--" }}</el-tag>
          </template>
        </el-table-column> -->
        <el-table-column width="130px" label="实际总费用(元)">
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.totalAmount ? $round(scope.row.totalAmount) : "服务完成后计算" }}</el-tag>
          </template>
        </el-table-column>
		<el-table-column width="130px" label="已支付押金(元)">
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.depositAmount ?  $round(scope.row.depositAmount)  : "0" }}</el-tag>
          </template>
        </el-table-column>
       
        <el-table-column label="操作" width="500px" fixed="right">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status == 0 || scope.row.status == 1"
              type="fail"
              size="mini"
              icon="el-icon-close"
              @click="closeOrder(scope.row)"
              >关闭订单</el-button
            >
            <!-- <el-button
              v-if="scope.row.status == 10"
              type="primary"
              size="mini"
              icon="el-icon-close"
              @click="confirmOrder(scope.row)"
              >确认订单</el-button
            > -->
            <!-- <el-button
						  v-if="scope.row.status == 1"
						  type="success"
						  size="mini"
						  icon="el-icon-right"
						  @click="intelligentAllocate"
						>
						  智能分配
						</el-button>
						<el-button
						  v-if="scope.row.status == 1"
						  type="success"
						  size="mini"
						  icon="el-icon-right"
						  @click="manualAllocate(scope.row.orderId)"
						>
						  手动分配
						</el-button> -->

            <el-button
              type="primary"
              size="mini"
              icon="el-icon-location"
              v-if="scope.row.status >= 1"
              @click="showDetail(scope.row)"
            >
              订单详情
            </el-button>
            <el-button
              v-if="scope.row.status >= 1"
              type="success"
              size="mini"
              icon="el-icon-location"
              @click="showProgress(scope.row)"
            >
              查看服务进度
            </el-button>
            <el-button   type="default"  size="mini" icon="el-icon-location" @click="viewLocation(scope.row)">查看位置</el-button >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>

    <el-dialog
      title="订单详细"
      :visible.sync="orderDetailDialogVisible"
      @close="orderDetailDialogClose"
      width="80%"
    >
      <ServiceOrderDetail :OrderNo="currentOrder.OrderNo"></ServiceOrderDetail>
    </el-dialog>

    <el-dialog
      title="查看服务进度"
      :visible.sync="progressDialogVisible"
      @close="progressDialogClose"
      width="80%"
    >
      <ServiceProgress :orderNo="currentOrder.OrderNo"></ServiceProgress>
    </el-dialog>

    <el-dialog
      :title="mapDialogTitle"
      :visible.sync="addressDialogVisible"
      width="50%"
      @close="addressDialogClosed"
    >
      <AmapChose
        ref="mapChoose"
        :initLocation="initLocation"
        :onlyShowMap="onlyShowMap"
        :show="addressDialogVisible"
        @placeChoose="placeChoose"
        @changeShow="changeShow"
      ></AmapChose>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelChooseAddress">取 消</el-button>
        <el-button type="primary" @click="confirmChooseAddress"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import { serviceOrderList, machines } from "@/config/config.js";
import { serviceOrderStatus } from "@/config/index.js";
import ServiceProgress from "./ServiceProgress.vue";
import ServiceOrderDetail from "./ServiceOrderDetail.vue";
import AmapChose from "@/components/amap-choose/amap-choose.vue";
import { myMixins } from "@/mixins/myMixins.js";
export default {
  mixins: [myMixins],
  data() {
    return {
      queryInfo: {
        // action: "serviceOrderList",
        query: "",
        status: -1,
        page: 1,
        limit: 10,
        // path: "ashxWeb/Servicesorder.ashx",
      },
      total: 0,
      list: [],
      tabPosition: "left",
      allocateDialogVisible: false,
      progressDialogVisible: false,
      orderDetailDialogVisible: false,
      currentOrder: {},
      //地图组件
      initLocation: "",
      addressDialogVisible: false,
      tempAddressInfo: {},
      mapDialogTitle: "选择地址",
      onlyShowMap: false,
    };
  },
  components: {
    ServiceProgress,
    ServiceOrderDetail,
    AmapChose
  },
  computed: {
    machineList() {
      return machines;
    },
  },
  created() {
    this.getList();
    // this.currentOrder = serviceOrderList[0];
    // console.log(this.currentOrder)
  },
  methods: {
    getStatus(status) {
      return serviceOrderStatus[status];
    },
    async closeOrder(order) {
      console.log(order);
      const { data: res } = await this.$http.post("openapi/proxy_post", {
        path: "ashxWeb/Servicesorder.ashx",
        action: "serviceOrder_updatestatus",
        OrderNo: order.OrderNo,
        status: "-99"
      });
      if (res.code !== 200 || res.data[0].code == -1)
        return this.$message.error("取消订单失败！");
      this.$message.success("取消订单成功!");
      this.allocateDialogVisible = false;
      this.getList()
    },
    confirmOrder(order) {
      console.log(order);
    },
    openLocation() {},
    async getList() {
      let params = _.cloneDeep(this.queryInfo);
      // params.page--;
      const { data: res } = await this.$http.get("web_api/machine_service/order_list", {
        params
      });
      if (res.code !== 200) {
        return this.$message.error("获取订单列表失败！");
      }
      this.list = res.data;
      this.total = res.total;
    },
    // 分页
    handleSizeChange(newLimit) {
      this.queryInfo.limit = newLimit;
      this.getList();
    },
    handleCurrentChange(newSize) {
      this.queryInfo.page = newSize;
      this.getList();
    },
    addressDialogClosed() {
      this.allocateDialogVisible = false;
    },
    async showProgress(scope) {
      // 供测试的物流单号：1106975712662
      // const { data: res } = await this.$http.get("/kuaidi/1106975712662");
      // if (res.meta.status !== 200) {
      //   return this.$message.error("获取物流进度失败!");
      // }
      // this.progressInfo = res.data;
      this.currentOrder = scope;
      this.progressDialogVisible = true;
    },
    viewStack() {},
    showDetail(scope) {
      console.log(scope);
      this.currentOrder = scope;
      this.orderDetailDialogVisible = true;
    },
    progressDialogClose(){
      this.currentOrder = {};
      this.progressDialogVisible = false;
    },
    orderDetailDialogClose(){
      this.currentOrder = {};
      this.orderDetailDialogVisible = false;
    },
    changeShow(status) {
      // this.addressDialogVisible = status;
      // this.goBack();
    },
    placeChoose(info) {
      console.log(info);
      // this.$setGlobalData("choosedLocation", info)
      // this.goBack();
    },
    viewLocation(row) {
      this.initLocation = `${row.lng}, ${row.lat}`;
      this.mapDialogTitle = "查看位置";
      this.onlyShowMap = true;
      this.addressDialogVisible = true;
    },
    cancelChooseAddress() {
      this.addressDialogVisible = false;
    },
    confirmChooseAddress() {
      this.$refs["mapChoose"].confirm();
      this.addressDialogVisible = false;
    },
    addressDialogClosed() {
      this.addressDialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.el-cascader {
  width: 100%;
}
</style>
