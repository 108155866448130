<template>
<div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>资讯管理</el-breadcrumb-item>
        <el-breadcrumb-item>资讯列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
        <el-row :gutter="20">
            <el-col :span="6">
                <el-input placeholder="请输入内容" v-model="queryInfo.query" clearable @clear="getNewsList">
                    <el-button slot="append" icon="el-icon-search" @click="getNewsList"></el-button>
                </el-input>
            </el-col>
            <el-col :span="4">
                <el-button type="primary" @click="goAddPage">添加资讯</el-button>
            </el-col>
        </el-row>
        <!-- 表格数据 -->
        <el-table :data="newsList" border stripe>
            <el-table-column type="index"></el-table-column>
            <el-table-column label="资讯首图" width="220px">
                <template slot-scope="scope"><img :src="scope.row.image" width="200px" height="auto" /></template>
            </el-table-column>
            <el-table-column label="资讯标题" prop="title" width="300px"></el-table-column>
            <el-table-column label="资讯详情" :show-overflow-tooltip="true" prop="description" width="400px"></el-table-column>
            <el-table-column label="资讯种类" width="100px">
                <template slot-scope="scope">
                    <el-tag type="success" size="mini">{{scope.row.type}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="阅读量" prop="viewCount" width="140px"></el-table-column>
            <el-table-column label="创建时间" width="240px">
                <template slot-scope="scope">{{scope.row.createtime | dataFormat }}</template>
            </el-table-column>
            <el-table-column label="操作" width="200px" fixed="right">
                <template slot-scope="scope">
                    <el-button type="primary" icon="el-icon-edit" size="mini" @click="editInfo(scope.row)"></el-button>
                    <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeById(scope.row.id)"></el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page" :page-sizes="[10, 15, 20]" :page-size="queryInfo.limit" layout="total, sizes, prev, pager, next, jumper" :total="total" background></el-pagination>
    </el-card>

    <el-dialog :title="isEdit ? '修改资讯信息' : '添加资讯信息'" :visible.sync="editDialogVisible" width="50%">
        <AddNews :detail="currentDetail" :isEdit="isEdit" @refreshList="refreshList" @close="editDialogVisible = false" />
    </el-dialog>
</div>
</template>

<script>
import {
    newsList
} from "@/config/config.js";
import AddNews from "./Add.vue";
export default {
    components: {
        AddNews
    },
    data() {
        return {
            queryInfo: {
                action: "newsList",
                query: "",
                page: 1,
                limit: 10,
                path: "ashxWeb/news.ashx",
            },
            // 资讯列表
            newsList: [],
            // 资讯总数
            total: 0,
            currentDetail: null,
            isEdit: false,
            editDialogVisible: false
        }
    },
    created() {
        this.getNewsList()
    },
    methods: {
        refreshList() {
            console.log("更新列表")
            this.getNewsList();
            this.editDialogVisible = false;
        },
        // 根据分页获取对应的资讯列表
        async getNewsList() {
            let params = _.cloneDeep(this.queryInfo);
            params.page--;
            const {
                data: res
            } = await this.$http.get("openapi/web_proxy_get", {
                params,
            });
            if (res.code !== 200) {
                return this.$message.error("获取供应商列表失败！");
            }
            this.newsList = res.data;
            this.total = res.total || this.newsList.length;
        },
        handleSizeChange(newSize) {
            this.queryInfo.limit = newSize
            this.getNewsList()
        },
        handleCurrentChange(newSize) {
            this.queryInfo.page = newSize
            this.getNewsList()
        },
        // 通过Id删除资讯
        async removeById(id) {
            this.$confirm('是否删除该资讯', '温馨提示', {
                confirmButtonText: '确定',
                callback: async (action) => {
                    if (action == 'confirm') {
                        const {
                            data: res
                        } = await this.$http.post("openapi/proxy_post", {
                            action: "news_delete",
                            path: "ashxWeb/news.ashx",
                            id
                        });
                        if (res.code !== 200) {
                            return this.$message.error("操作失败！");
                        }
                        this.$message.success("操作成功！");
                        this.getNewsList();
                    }
                }
            });
        },
        goAddPage() {
            // this.$router.push('/goods/add')
            this.isEdit = false;
            this.currentDetail = null;
            this.editDialogVisible = true;
        },
        updateStatus(id, flag) {},
        editInfo(current) {
            this.isEdit = true;
            setTimeout(() => {
                this.currentDetail = current;
                this.editDialogVisible = true;
            }, 100);

        },
        closeDialog() {
            this.isEdit = false;
            this.currentDetail = null;
            this.editDialogVisible = false;
        },
    }
}
</script>

<style lang="less" scoped>
  </style>
