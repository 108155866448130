<template>
  <div class="block">
    <!-- 表格数据 -->
    <el-table :data="machineList" border stripe>
      <el-table-column width="150px" label="农机图片">
        <template slot-scope="scope">
          <img :src="scope.row.image" width="150px" height="auto" />
        </template>
      </el-table-column>
      <el-table-column
        width="150px"
        label="农机名称"
        prop="name"
      ></el-table-column>
      <el-table-column width="100px" label="服务类型">
        <template slot-scope="scope">
          <el-tag type="success">{{ scope.row.typeName }}</el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column width="100px" label="服务品种">
				<template slot-scope="scope"><el-tag v-for="item in scope.row.variety">{{
              item
            }}</el-tag></template>
			</el-table-column> -->
      <el-table-column width="150px" label="农机来源">
        <template slot-scope="scope">
          {{ scope.row.fromTypeName }}
        </template>
      </el-table-column>
      <el-table-column width="100px" label="农机手">
        <template slot-scope="scope">
          {{ scope.row.driverName ? scope.row.driverName : "未绑定" }}
        </template>
      </el-table-column>
      <el-table-column width="150px" label="农机手联系方式">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" icon="el-icon-phone">{{
            scope.row.mobile ? scope.row.mobile : "未绑定"
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column width="300px" label="当前位置">
        <template slot-scope="scope">
          {{ scope.row.lastUploadLocation || "未上报" }}
        </template>
      </el-table-column>
      <el-table-column width="150px" label="距离目标(公里)">
        <template slot-scope="scope">
          {{ scope.row.farAway || "--" }}
        </template>
      </el-table-column>
      <el-table-column width="100px" label="当前状态">
        <template slot-scope="scope">
          <div v-html="getMachineStatus(scope.row.status)"></div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200px" fixed="right">
        <template slot-scope="scope">
          <el-button
            type="primary"
            icon="el-icon-right"
            @click="dispatchAction(scope.row.id)"
            >指派它</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.page"
      :page-sizes="[10, 15, 20]"
      :page-size="queryInfo.limit"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      background
    ></el-pagination>
  </div>
</template>

<script>
import { machines } from "@/config/config.js";
import { machineStatus } from "@/config/index.js";
export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      queryInfo: {
        action: "MachineList",
        query: "",
        page: 0,
        limit: 10,
        path: "ashxWeb/Machine.ashx",
        status: 0,
      },
      total: 0,
      loading: false,
      dataEmpty: false,
      activeColor: "#FFBD32",
      machineList: [],
    };
  },
  computed: {},
  created() {
    this.getMachineList();
  },
  methods: {
    // 分页
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.getMachineList();
    },
    handleCurrentChange(newSize) {
      this.queryInfo.page = newSize;
      this.getMachineList();
    },
    getMachineStatus(status) {
      return machineStatus[status];
    },
    handleDateChange() {},
    async getMachineList() {
      const { data: res } = await this.$http.get("openapi/web_proxy_get", {
        params: this.queryInfo,
      });
      if (res.code !== 200) {
        return this.$message.error("获取农机列表失败！");
      }
      this.machineList =
        res.data && typeof res.data == "object" ? res.data : machines;
      //   console.log(this.list)
      this.total = res.total || this.machineList.length;
      // this.machineList = machines;
    },
    async dispatchAction(id) {
      this.$emit("dispatchAction", id);
    },
  },
};
</script>
