<template>
<div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>资讯管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/news' }">资讯列表</el-breadcrumb-item>
        <el-breadcrumb-item>添加资讯</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
        <!-- 提示 -->
        <el-alert title="添加资讯信息" type="info" center show-icon :closable="false"></el-alert>

        <!-- Tab栏 -->
        <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px" label-position="top">
            <el-form-item label="资讯名称">
                <el-input v-model="addForm.title"></el-input>
            </el-form-item>
            <!-- <el-form-item label="资讯副标题" prop="news_subtitle">
          <el-input v-model="addForm.news_subtitle" type="number"></el-input>
        </el-form-item> -->
            <el-form-item label="资讯分类">
                <el-select v-model="addForm.type" placeholder="请选择资讯分类">
                    <el-option v-for="item in newsTypeList" :key="item.value" :label="item.name" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <!-- action: 图片上传的API接口地址 -->
            <el-form-item label="农场图片">
                <upload name="image" :initFileList="initFileList" @handleUploadSuccess="handleUploadSuccess" @handleRemove="handleRemove"></upload>
            </el-form-item>
            <el-form-item label="资讯详情介绍">
              <quill-editor v-model="addForm.description"></quill-editor>
                <!-- <el-input type="textarea" :autosize="{ minRows: 5, maxRows: 20 }" placeholder="资讯详情介绍" v-model="addForm.description">
                </el-input> -->
            </el-form-item>
            <el-button type="primary" class="btnAdd" @click="addnews">添加资讯</el-button>
        </el-form>
    </el-card>
</div>
</template>

<script>
import _ from "lodash";
import upload from "@/components/upload";
import { Base64 } from "js-base64";
export default {
    components: {
        upload
    },
    props: {
        detail: Object,
        isEdit: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            // 步骤条默认激活 与左侧Tab联动
            activeIndex: "0",
            // 添加资讯的表单对象
            addForm: {
                title: "",
                image: "",
                type: "",
                description: ""
            },
            addFormRules: {
                title: [{
                    required: true,
                    message: "请输入资讯标题",
                    trigger: "blur",
                }],
                description: [{
                    required: true,
                    message: "请输入资讯详情",
                    trigger: "blur",
                }],
                image: [{
                    required: true,
                    message: "请输入资讯主图",
                    trigger: "blur",
                }],
                type: [{
                    required: true,
                    message: "请输入资讯类型",
                    trigger: "blur",
                }]
            },
            // 资讯列表
            newsTypeList: [{
                name: "地方新闻",
                value: 1
            }, {
                name: "政策解读",
                value: 2
            }, {
                name: "专家点评",
                value: 3
            }],
            initFileList: []
        }
    },
    created() {

    },
    watch: {
        detail: {
            handler(nval, oval) {
                if (nval) {
                    let _newObj = _.cloneDeep(nval);
                  
                    for (let key in this.addForm) {
                      if(key == 'description'){
                        this.addForm[key]  = decodeURIComponent(_newObj[key])//Base64.decode( _newObj[key] )
                      }else{
                        this.addForm[key] = _newObj[key];
                      }
                        
                    }
                    if (this.addForm.image) {
                        this.initFileList = [{
                            url: this.addForm.image
                        }];
                    } else {
                        this.initFileList = [];
                    }
                } else {

                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {

    },
    methods: {
        handleRemove() {
            this.addForm.image = "";
        },
        // 监听图片上传成功事件
        handleUploadSuccess(response) {
            this.addForm.image = response.value[0].url;
        },
        // 添加资讯
        addnews() {
            this.$refs.addFormRef.validate(async (valid) => {
                if (!valid) return this.$message.error("请填写必要的表单项！");
                // 发送请求前：需对提交的表单进行处理news_cat attrs
                // this.addForm.news_cat = this.addForm.news_cat.join(',')
                // 以上写法不对：级联选择器绑定的对象news_cat要求是数组对象
                // 解决办法: 包：lodash 方法（深拷贝）：cloneDeep(boj)

                let params = _.cloneDeep(this.addForm);
                params.description = encodeURIComponent(params.description);//Base64.encode(params.description);
                if (this.isEdit) {
                    params.action = "news_edit";
                    params.id = this.detail.id;
                } else {
                    params.action = "news_add";
                }
                params.path = "/ashxWeb/news.ashx";
                const {
                    data: res
                } = await this.$http.post(
                    "/openapi/proxy_post",
                    params
                );
                if (res.code !== 200) return this.$message.error("添加资讯失败！");
                this.$message.success("添加资讯成功!");
                // this.$router.push("/service");
                this.$emit("refreshList");
            });
        },
    },
};
</script>

<style lang="less" scoped>
.el-checkbox {
    margin: 0 8px 0 0 !important;
}

.previewImg {
    width: 100%;
}

.btnAdd {
    margin-top: 15px;
}
</style>
