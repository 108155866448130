// 在 myMixins.js 中定义一个 age 字段和 getAge 方法,用来获取年龄信息
export const myMixins = {
    data() {
        return {
            tableHeight: "600px",
        }
    },
    mounted() {
        // alert(1)
        this.getAutoHeight();
    },
    methods: {
        getAutoHeight() {
            let el = document.querySelector("#mainTable");
            let elParent = el.parentNode;
            let pt = this.getStyle(elParent, "paddingTop");
            let pb = this.getStyle(elParent, "paddingBottom");
            this.$nextTick(() => {
                this.tableHeight = elParent.clientHeight - (pt + pb + 50) + "px";
                console.log(this.tableHeight)
            });
        },
        getStyle(obj, attr) {
            let result = obj.currentStyle
                ? obj.currentStyle[attr].replace("px", "")
                : document.defaultView
                    .getComputedStyle(obj, null)
                [attr].replace("px", "");
            return Number(result);
        }
    }
}
