<template>
	<div>
		<!-- 面包屑导航区 -->
		<!--    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/services' }">服务管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加服务</el-breadcrumb-item>
    </el-breadcrumb> -->
		<!-- 卡片视图 -->
		<el-card>
			<!-- 提示 -->
			<el-alert title="添加服务信息" type="info" center show-icon :closable="false"></el-alert>
			<!-- 步骤条 -->
			<!-- Tab栏 -->
			<el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px" label-position="top">
				<!-- <el-tabs v-model="activeIndex" :tab-position="'left'" :before-leave="beforeTabLeave" @tab-click="tabClicked"> -->
				<!-- <el-tab-pane label="基本信息" name="0"> -->
				<el-form-item label="服务名称" prop="name">
					<el-input class="form-item-input" placeholder="在此输入服务名称" v-model="addForm.name"></el-input>
				</el-form-item>

				<el-form-item label="服务类型" prop="type">
					<el-select class="form-item-input" v-model="addForm.type" placeholder="请选择服务类型">
						<el-option v-for="item in serviceTypeList" :key="item.value" :label="item.name"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="服务品种" prop="variety">
					<el-select class="form-item-input" v-model="addForm.variety" placeholder="请选择服务品种">
						<el-option v-for="item in serviceVarietyList" :key="item.value" :label="item.name"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="服务计量单位" prop="unit">
					<el-input class="form-item-input" v-model="addForm.unit" placeholder="在此输入服务计量单位,例如亩,公斤"
						type="text"></el-input>
				</el-form-item>
				<el-form-item label="服务价格（元/亩）" prop="price">
					<el-input class="form-item-input" v-model="addForm.price" placeholder="在此输入服务价格"
						type="number"></el-input>
				</el-form-item>
				<!-- action: 图片上传的API接口地址 -->
				<el-form-item label="服务图片">
					<upload :name="uploadName" :initFileList="initFileList" :multiple="isMultiple"
						@handleUploadSuccess="handleUploadSuccess" @handleRemove="handleRemove"></upload>
				</el-form-item>
				<el-form-item label="服务内容介绍">
					<el-input type="textarea" :autosize="{ minRows: 5, maxRows: 20}" placeholder="服务内容介绍"
						v-model="addForm.description">
					</el-input>
				</el-form-item>
				<!-- <el-form-item label="服务内容介绍">
			<upload
			  name="service_desc_image"
			  @handleUploadSuccess="handleUploadSuccess" @handleRemove="handleRemove"
			></upload>
          <quill-editor
            ref="machine_editor"
            placeholder="在此输入服务内容"
            v-model="addForm.introduce"
          ></quill-editor>
		  -->
				<el-button type="primary" class="btnAdd" @click="addAction">添加服务</el-button>
				<!-- </el-form-item> -->
			</el-form>
		</el-card>
	</div>
</template>

<script>
	import _ from "lodash";
	import {
		serviceTypeList,
		serviceVarietyList
	} from "@/config/config.js";
	import upload from "@/components/upload";
	export default {
		components: {
			upload
		},
		props: {
			detail: Object,
			isEdit: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				// 步骤条默认激活 与左侧Tab联动
				activeIndex: "0",
				isMultiple: true,
				uploadName: "service_image",
				// 添加服务的表单对象
				addForm: {
					name: "",
					price: "",
					type: "",
					variety: "",
					// 服务所属分类数组
					description: "",
					// 图片的数组
					image: "",
					unit: "",
				},
				initFileList: [],
				addFormRules: {
					name: [{
						required: true,
						message: "请输入服务名称",
						trigger: "blur",
					}],
					unit: [{
						required: true,
						message: "请输入服务计量单位",
						trigger: "blur",
					}],
					price: [{
						required: true,
						message: "请输入服务价格",
						trigger: "blur",
					}],
					type: [{
						required: true,
						message: "请选择服务类型",
						trigger: "blur",
					}, ],
					variety: [{
						required: true,
						message: "请选择服务品种",
						trigger: "blur",
					}, ],
					description: [{
						required: true,
						message: "请输入服务介绍",
						trigger: "blur",
					}, ],
					image: [{
						required: true,
						message: "请上传服务图片",
						trigger: "blur",
					}, ],
				},
				// 动态参数列表数据
				manyTableData: [],
				// 静态属性列表数据
				onlyTableData: [],
				// 图片上传组件的请求对象
				headerObj: {
					Authorization: window.sessionStorage.getItem("token"),
				},
				// serviceTypeList: [],
				// serviceVarietyList: []
			};
		},
		watch: {
			detail: {
				handler(nval, oval) {
					if (nval) {
						nval.type = nval.type ? "" + nval.type : null;
						nval.variety = nval.variety ? "" + nval.variety : null;
						let _newObj = _.cloneDeep(nval);
						_newObj.price = this.$round(_newObj.price);
						for (let key in this.addForm) {
							this.addForm[key] = _newObj[key];
						}
						if (this.addForm.image) {
							this.initFileList = [{
								url: this.addForm.image
							}];
						} else {
							this.initFileList = [];
						}
					} else {

					}
				},
				deep: true,
				immediate: true
			}
		},
		updated() {
			if (this.isEdit) {

			}
		},
		created() {

		},
		computed: {
			serviceTypeList() {
				return this.$parent.$parent.serviceTypeList;
			},
			serviceVarietyList() {
				return this.$parent.$parent.serviceVarietyList;
			}
		},
		methods: {
			handleRemove() {
				this.addForm.image = ""
			},
			// 监听图片上传成功事件
			handleUploadSuccess(response) {
				if (response && response.name == this.uploadName) {
					this.addForm.image = response.value[0].url;
				}
			},
			// 添加服务
			addAction() {
				this.$refs.addFormRef.validate(async (valid) => {
					if (!valid) return this.$message.error("请填写必要的表单项！");
					let params = _.cloneDeep(this.addForm)
					params.type = Number(params.type);
					params.variety = Number(params.variety);
					params.price = params.price * 100;
					if (this.isEdit) {
						params.action = "ServiceTypeUpdate"
						params.id = this.detail.ID
					} else {
						params.action = "ServiceTypeAdd";
					}
					params.path = "ashxWeb/MaServices.ashx"
					const {
						data: res
					} = await this.$http.post("/openapi/proxy_post", params);
					if (res.code !== 200)
						return this.$message.error("添加服务失败！");
					this.$message.success("添加服务成功!");
					// this.$router.push("/service");
					this.$emit("refreshList");
				});
			},
		},
	};
</script>

<style lang="less" scoped>
	.el-checkbox {
		margin: 0 8px 0 0 !important;
	}

	.previewImg {
		width: 100%;
	}

	.btnAdd {
		margin-top: 15px;
	}

	.form-item-input {
		width: 400px
	}
</style>