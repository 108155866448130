<template>
<div>
  <!-- 面包屑导航区 -->
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>订单管理</el-breadcrumb-item>
    <el-breadcrumb-item>服务订单列表</el-breadcrumb-item>
  </el-breadcrumb>
  <!-- 卡片视图 -->
  <el-card>
    <el-row>
      <el-col :span="6">
        <el-input placeholder="请输入内容">
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </el-col>
    </el-row>

    <!-- 订单列表 -->
    <el-table :data="list" border stripe>
      <!-- <el-table-column type="index" label="#"></el-table-column> -->
      <el-table-column label="订单编号" width="200px" prop="orderno"></el-table-column>
      <el-table-column label="认养批次编号" width="200px" prop="breedNo"></el-table-column>
      <el-table-column width="150px" label="当前状态">
        <template slot-scope="scope">
          <el-tag type="danger" size="mini">{{
            getStatus(scope.row.status)
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="180px" label="认养图片"> <template slot-scope="scope"><img :src="scope.row.image" width="120px" height="120px" /></template></el-table-column>
      <el-table-column width="200px" label="认养名称" prop="productName"></el-table-column>
      <!-- <el-table-column width="100px" label="认养品种">
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.category }}</el-tag>
          </template>
        </el-table-column> -->
      <el-table-column width="100px" label="农产品规格">
        <template slot-scope="scope">
          <el-tag type="success">{{ scope.row.spec }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="100px" label="认养价格(元)">
        <template slot-scope="scope">
          <el-tag type="success">{{
            $round(scope.row.productPrice)
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="100px" label="认养数量">
        <template slot-scope="scope">
          <el-tag type="success">{{ scope.row.number }}{{ scope.row.productUnit }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="200px" label="预期收成时间">
        <template slot-scope="scope">
          <el-tag type="success">{{ scope.row.endDate }}</el-tag>
        </template>
      </el-table-column>
     
      <el-table-column width="200px" label="下单时间">
        <template slot-scope="scope">{{
          scope.row.createtime | dataFormat
        }}</template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="300px">
        <template slot-scope="scope">
          <el-button v-if="scope.row.status == 0 || scope.row.status == 1" type="primary" size="mini" icon="el-icon-close"
            @click="updateStatus(scope.row, -99)">取消订单</el-button>
          <el-button v-if="scope.row.status == 1" type="success" size="mini" icon="el-icon-location" @click="updateStatus(scope.row, 2)">接受订单</el-button>
          <el-button v-if="scope.row.status == 2"  type="success" size="mini" icon="el-icon-location" @click="updateStatus(scope.row, 3)">配送完成</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page"
      :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.limit" layout="total, sizes, prev, pager, next, jumper"
      :total="total"></el-pagination>
  </el-card>
</div>
</template>

<script>
import cityData from "@/config/citydata.js";
import { farmOrderList } from "@/config/config.js";
import { farmOrderStatus } from "@/config/index.js";
export default {
  data() {
    return {
      // 订单列表查询参数
      queryInfo: {
        action: "productOrderList",
        query: "",
        page: 1,
        limit: 10,
        status: -1,
        path: "ashxWeb/web_farm.ashx",
      },
      total: 0,
      // 订单列表
      list: [],
      // 修改地址对话框
      addressDialogVisible: false,
      addressForm: {
        address1: [],
        address2: "",
      },
      addressFormRules: {
        address1: [
          { required: true, message: "请选择省市区县", trigger: "blur" },
        ],
        address2: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
      },
      cityData,
      // 物流进度对话框
      progressDialogVisible: false,
      // 物流进度
      progressInfo: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getStatus(status) {
      return farmOrderStatus[status];
    },
    async getList() {
      let params = _.cloneDeep(this.queryInfo);
      params.page--;
      const { data: res } = await this.$http.get("/openapi/web_proxy_get", {
        params,
      });
      if (res.code !== 200) {
        return this.$message.error("获取订单列表失败！");
      }
      this.list = res.data;
      this.total = res.total || this.list.length;
    },
    // 分页
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.getList();
    },
    handleCurrentChange(newSize) {
      this.queryInfo.page = newSize;
      this.getList();
    },
    showEditDialog() {
      this.addressDialogVisible = true;
    },
    addressDialogClosed() {
      this.$refs.addressFormRef.resetFields();
    },
    async showProgressDialog() {
      // 供测试的物流单号：1106975712662
      const { data: res } = await this.$http.get("/kuaidi/1106975712662");
      if (res.meta.status !== 200) {
        return this.$message.error("获取物流进度失败!");
      }
      this.progressInfo = res.data;
      this.progressDialogVisible = true;
    },
    updateStatus(order, status) {
      let title;
      switch (status) {
        case 2:
          title = "是否确认接受此订单";
          break;
        case 3:
          title = "是否确认完结该订单";
          break;
        case -99:
          title = "是否确认取消该订单";
          break;
      }
      this.$confirm(title, '温馨提示', {
        confirmButtonText: '确定',
        callback: async (action) => {
          if (action == 'confirm') {
            const {
              data: res
            } = await this.$http.post("openapi/proxy_post", {
              action: "Order_updatestatus",
              path: "ashxWeb/web_farm.ashx",
              status,
              orderNo: order.orderno
            });
            if (res.code !== 200) {
              return this.$message.error("操作失败！");
            }
            this.$message.success("操作成功！");
            this.getList();
          }
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>
.el-cascader {
  width: 100%;
}
</style>
