<template>
  <div>
    <!-- 面包屑导航区 -->
    <!--    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>农场管理</el-breadcrumb-item>
      <el-breadcrumb-item>农产品列表</el-breadcrumb-item>
    </el-breadcrumb> -->
    <!-- 卡片视图 -->
    <el-card>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input
            placeholder="请输入内容"
            v-model="queryInfo.query"
            clearable
            @clear="getGoodsList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getGoodsList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="goAddPage">添加农产品</el-button>
        </el-col>
      </el-row>
      <!-- 表格数据 -->
      <el-table :data="goodsList" border stripe>
        <!-- <el-table-column type="index"></el-table-column> -->
        <el-table-column
          label="农产品名称"
          width="150px"
          prop="name"
        ></el-table-column>
        <el-table-column label="农产品图片" width="125px">
          <template slot-scope="scope"
            ><img :src="scope.row.image" width="100px" height="100px"
          /></template>
        </el-table-column>
        <el-table-column
          label="农产品价格(元)"
          width="150px"
        >
        <template slot-scope="scope">
          {{ $round(scope.row.price) }}
          </template>
      </el-table-column>
		 <el-table-column label="农产品规格" width="150px">
          <template slot-scope="scope">
            <el-tag type="success" size="mini">{{ scope.row.spec }}</el-tag>
          </template>
      </el-table-column>
		<el-table-column label="视频监控" >
          <template slot-scope="scope">{{
            scope.row.videoUrl
          }}</template>
        </el-table-column>
        <!-- <el-table-column label="农产品类别" width="150px">
          <template slot-scope="scope">
            <el-tag type="success" size="mini">{{ scope.row.category }}</el-tag>
          </template>
        </el-table-column> -->
		<el-table-column label="农产品介绍" >
          <template slot-scope="scope">{{
            scope.row.description
          }}</template>
        </el-table-column>
        <el-table-column label="创建时间" width="180px">
          <template slot-scope="scope">{{
            scope.row.createtime | dataFormat
          }}</template>
        </el-table-column>
        <el-table-column label="状态" width="180px">
          <template slot-scope="scope">
            <el-tag type="danger" size="mini" v-if="scope.row.status == 0"
              >已下架</el-tag
            >
            <el-tag type="success" size="mini" v-else>已上架</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200px" fixed="right">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status == 0"
              type="success"
              icon="el-icon-down"
              size="mini"
              @click="updateStatus(scope.row.productId, 1)"
              >上架</el-button
            >
            <el-button
              v-else
              type="danger"
              icon="el-icon-up"
              size="mini"
              @click="updateStatus(scope.row.productId, 0)"
              >下架</el-button
            >
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="editInfo(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="removeById(scope.row.id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </el-card>
    <el-dialog
      :title="isEdit ? '修改农产品信息' : '添加农产品信息'"
      :visible.sync="editDialogVisible"
      width="50%"
    >
      <AddProduct
        :detail="currentDetail"
        :isEdit="isEdit"
        @refreshList="refreshList"
        @close="editDialogVisible = false"
      />
    </el-dialog>
  </div>
</template>

<script>
import { farm_goodsList } from "@/config/config.js";
import AddProduct from "./Add.vue";
export default {
  components: {
    AddProduct,
  },
  data() {
    return {
      queryInfo: {
        action: "productList",
        query: "",
        page: 1,
        limit: 10,
        status: -1,
        path: "ashxWeb/web_farm.ashx",
      },
      // 农产品列表
      goodsList: [],
      // 农产品总数
      total: 0,
      editDialogVisible: false,
      currentDetail: null,
      isEdit: false,
      farmList: [],
    };
  },
  created() {
    this.getGoodsList();
    this.getDictionaryList();
    this.getFarmList();
  },
  methods: {
    refreshList() {
      this.getGoodsList();
      this.editDialogVisible = false;
    },
    async getDictionaryList() {
      this.$getDicList(13).then((list) => {
        this.goodsTypeList = list;
      });
    },
    // 根据分页获取对应的农产品列表
    async getGoodsList() {
      let params = _.cloneDeep(this.queryInfo);
      params.page--;
      const { data: res } = await this.$http.get("openapi/web_proxy_get", {
        params,
      });
      if (res.code !== 200) {
        return this.$message.error("获取商品列表失败！");
      }
      this.goodsList =
        res.data && typeof res.data == "object" ? res.data : supplierList;
      this.total = res.total || this.goodsList.length;
    },
    // 根据分页获取对应的农场列表
    async getFarmList() {
      const { data: res } = await this.$http.get("openapi/web_proxy_get", {
        params: {
          page: 0,
          limit: 100,
          action: "farmList",
          path: "ashx/farm.ashx",
          status: 1,
        },
      });
      if (res.code !== 200) {
        return this.$message.error("获取农场列表失败！");
      }
      this.farmList = res.data;
    },
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.getGoodsList();
    },
    handleCurrentChange(newSize) {
      this.queryInfo.page = newSize;
      this.getGoodsList();
    },
    // 通过Id删除农产品
    async removeById(id) {
      const confirmResult = await this.$confirm(
        "此操作将永久删除该农产品, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除！");
      }
      const { data: res } = await this.$http.delete("goods/" + id);
      if (res.meta.status !== 200) {
        return this.$message.error("删除农产品失败！");
      }
      this.$message.success("删除农产品成功！");
      this.getGoodsList();
    },
    goAddPage() {
      // this.$router.push('/farms/product_add')
      this.isEdit = false;
      this.currentDetail = null;
      this.editDialogVisible = true;
    },
    updateStatus(id, flag) {
      this.$confirm(flag == 1 ? '是否确认上架该商品' : '是否确认下架该商品', '温馨提示', {
					confirmButtonText: '确定',
					callback: async (action) => {
						if (action == 'confirm') {
							const {
								data: res
							} = await this.$http.post("openapi/proxy_post", {
								action: "product_status_update",
								path: "ashxWeb/web_farm.ashx",
								status: flag,
								id
							});
							if (res.code !== 200) {
								return this.$message.error("操作失败！");
							}
							this.$message.success("操作成功！");
							this.getGoodsList();
						}
					}
				});
    },
    editInfo(current) {
      this.isEdit = true;
      if (current && current.products) {
        let list = current.products.map((item) => {
          return item.id;
        });
        current.products = list;
      }
      this.currentDetail = current;
      this.editDialogVisible = true;
    },
    closeDialog() {
      this.isEdit = false;
      this.currentDetail = null;
      this.editDialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped></style>
