<template>
  <div>
    <!-- 面包屑导航区 -->
    <!--   <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>农场管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/products_farm' }"
        >农产品列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>添加农产品</el-breadcrumb-item>
    </el-breadcrumb> -->
    <!-- 卡片视图 -->
    <el-card>
      <!-- 提示 -->
      <el-alert
        title="添加农产品信息"
        type="info"
        center
        show-icon
        :closable="false"
      ></el-alert>
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="100px"
        label-position="top"
      >
        <el-form-item label="养殖编号">
          <el-input v-model="addForm.breedNo"></el-input>
        </el-form-item>
        <el-form-item label="农产品名称">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="农产品价格">
          <el-input v-model="addForm.price"></el-input>
        </el-form-item>
        <el-form-item label="农产品类别">
          <el-select
            style="width: 100%"
            v-model="addForm.type"
            placeholder="请选择"
          >
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="农产品规格">
          <el-input
            v-model="addForm.spec"
            placeholder="请输入产品规格"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属农场">
          <el-select
            style="width: 100%"
            v-model="addForm.farmId"
            placeholder="请选择所属农场"
          >
            <el-option
              v-for="item in farmList"
              :key="item.id"
              :label="item.FarmName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="库存数量(份)">
          <el-input v-model="addForm.number" type="number"></el-input>
        </el-form-item>
        <el-form-item label="计量单位">
          <el-input v-model="addForm.unit" type="text"></el-input>
        </el-form-item>
        <el-form-item label="养殖周期(天)">
          <el-input v-model="addForm.during" type="number"></el-input>
        </el-form-item>
        <el-form-item label="周期开始时间">
          <el-date-picker
            v-model="addForm.startDate"
            @change="dateSelect"
            type="date"
            format="yyyy-MM-dd"
            start-placeholder="开始日期"
          >
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item label="周期结束时间">
					<el-input v-model="addForm.price"></el-input>
				</el-form-item> -->
        <el-form-item label="视频监控地址">
          <el-input v-model="addForm.videoUrl"></el-input>
        </el-form-item>

        <el-form-item label="农产品图片">
          <upload
            name="image"
            :initFileList="initFileList"
            @handleUploadSuccess="handleUploadSuccess"
            @handleRemove="handleRemove"
          ></upload>
        </el-form-item>
        <el-form-item label="农产品详情">
          <el-input
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 20 }"
            placeholder="农产品详情"
            v-model="addForm.description"
          >
          </el-input>
        </el-form-item>
        <el-button type="primary" class="btnAdd" @click="addAction"
          >添加农产品</el-button
        >
      </el-form>
    </el-card>
  </div>
</template>

<script>
import _ from "lodash";
import { attributeList, supplierList } from "@/config/config.js";
import upload from "@/components/upload";
export default {
  components: {
    upload,
  },
  props: {
    detail: Object,
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    detail: {
      handler(nval, oval) {
        // this.addForm = nval || {};
        this.addForm = nval ? _.cloneDeep(nval) : {};
        this.addForm.price = this.addForm.price ? this.$round( this.addForm.price) : 0;
						// for (let key in this.addForm) {
						// 	this.addForm[key] = _newObj[key];
						// }
        if (this.addForm.image) {
          this.initFileList = [
            {
              url: this.addForm.image,
            },
          ];
        } else {
          this.initFileList = [];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      addForm: {
        breedNo: "白鹅20240525",
        name: "皖西白鹅",
        type: "",
        spec: "50只/份",
        during: "30",
        startDate: "",
        endDate: "",
        image: "",
        price: "1000",
        description: "皖西白鹅介绍",
        unit: "",
        videoUrl: "",
        farmId: "",
      },
      dateRange: "",
      // 农产品列表
      typeList: [
        {
          name: "白鹅",
          value: 1,
        },
        {
          name: "黑猪",
          value: 2,
        },
      ],
      addFormRules: {
        name: [
          {
            required: true,
            message: "请输入农产品名称",
            trigger: "blur",
          },
        ],
        category: [
          {
            required: true,
            message: "请选择农产品分类",
            trigger: "blur",
          },
        ],
        price: [
          {
            required: true,
            message: "请选择农产品价格",
            trigger: "blur",
          },
        ],
        image: [
          {
            required: true,
            message: "请上传农产品照片",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "请填写农产品介绍",
            trigger: "blur",
          },
        ],
      },
      initFileList: [],
    };
  },
  created() {},
  computed: {
    farmList() {
      return this.$parent.$parent.farmList;
    },
  },
  methods: {
    // 级联选择器选中项变化时出发
    handleChange() {},
    // 监听图片上传成功事件
    handleRemove() {
      this.addForm.image = "";
    },
    // 监听图片上传成功事件
    handleUploadSuccess(response) {
      this.addForm.image = response.value[0].url;
    },
    // 添加农产品
    addAction() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) return this.$message.error("请填写必要的表单项！");
        // 发送请求前：需对提交的表单进行处理goods_cat attrs
        // this.addForm.goods_cat = this.addForm.goods_cat.join(',')
        // 以上写法不对：级联选择器绑定的对象goods_cat要求是数组对象
        // 解决办法: 包：lodash 方法（深拷贝）：cloneDeep(boj)

        let params = _.cloneDeep(this.addForm);
        params.price = params.price * 100;
        if (params.during && params.startDate) {
          console.log(params.startDate);
          let startDateTime = new Date(params.startDate);
          params.startDate = startDateTime.Format("yyyy-MM-dd");
          params.endDate = new Date(
            startDateTime.getTime() +
              parseInt(params.during) * 24 * 60 * 60 * 1000
          ).Format("yyyy-MM-dd");
        } else {
          return this.$message.error("开始时间或者周期不能为空");
        }
        if (this.isEdit) {
          params.action = "product_edit";
          params.id = this.detail.productId;
        } else {
          params.action = "product_add";
        }
        params.path = "ashxWeb/web_farm.ashx";
        const { data: res } = await this.$http.post(
          "/openapi/proxy_post",
          params
        );
        if (res.code !== 200) return this.$message.error("添加商品失败！");
        this.$message.success("添加商品成功!");
        // this.$router.push("/service");
        this.$emit("refreshList");
      });
    },
    dateSelect() {
      console.log(this.addForm.startDate);
    },
  },
};
</script>

<style lang="less" scoped>
.el-checkbox {
  margin: 0 8px 0 0 !important;
}

.previewImg {
  width: 100%;
}

.btnAdd {
  margin-top: 15px;
}
</style>
